import React from "react";
import { FaPlay, FaSync, FaStop } from "react-icons/fa";

const RehearsalArea = ({
  selectedActor,
  selectedDialogue,
  isPlaying,
  isProcessing,
  progress,
  error,
  onBack,
  onPlayTake,
  onCancelTake,
  onClearCache,
}) => {
  return (
    <div className="flex flex-col h-full">
      {/* Top Navigation */}
      <div className="flex items-center justify-between p-4 border-b border-gray-800">
        <button
          onClick={onBack}
          className="text-gray-400 hover:text-white transition-colors text-sm"
        >
          ← Dialogues
        </button>
        <div className="flex items-center gap-2">
          <span className="text-xl">{selectedActor.avatar}</span>
          <span className="text-white text-sm">{selectedActor.name}</span>
        </div>
      </div>

      {/* Main Content - Centered */}
      <div className="flex-1 flex flex-col justify-start p-4">
        <div className="w-full max-w-lg mx-auto">
          <p className="text-gray-400 text-sm md:text-lg mb-4">
            {selectedDialogue.context}
          </p>

          <div className="relative bg-gray-800 rounded-xl border border-gray-700 p-4 mb-4">
            <div
              className="absolute inset-0 bg-purple-500/20 rounded-xl transition-all duration-200"
              style={{ width: `${progress}%` }}
            />
            <p className="relative text-lg md:text-2xl italic text-gray-300">
              <span className="text-purple-400">"</span>
              {selectedDialogue.dialogue}
              <span className="text-purple-400">"</span>
            </p>
          </div>

          <div className="flex gap-3">
            <button
              onClick={isPlaying ? onCancelTake : onPlayTake}
              disabled={isProcessing}
              className={`flex-1 flex items-center justify-center gap-2 p-3 rounded-xl text-sm font-medium transition-colors ${
                isPlaying
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-gradient-to-r from-purple-600 to-pink-600"
              } text-white`}
            >
              {isProcessing ? (
                <>
                  <FaSync className="animate-spin" /> Processing...
                </>
              ) : isPlaying ? (
                <>
                  <FaStop /> Cut!
                </>
              ) : (
                <>
                  <FaPlay /> Action!
                </>
              )}
            </button>

            <button
              onClick={onClearCache}
              disabled={isProcessing}
              className="px-4 py-3 rounded-xl text-sm font-medium bg-gray-700 hover:bg-gray-600 text-white transition-colors"
              title="Clear Cache"
            >
              <FaSync />
            </button>
          </div>

          {error && (
            <div className="mt-3 p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RehearsalArea;
