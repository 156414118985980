import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import StoryDetails from "../components/StoryDetails";
import { useScene } from "../context/SceneContext";
import { getRandomScenePremise } from "../utils/premiseProvider";
import { generateNewScene } from "../api/scenes";
import { generateTitle } from "../api/stories";
import { FiLoader } from "react-icons/fi";
import {
  loadLastSceneFromLocalStorage,
  saveSceneToLocalStorage,
  loadSceneFromTitle,
} from "../utils/sceneStorageUtils";
import StoryTitle from "../components/StoryTitle";
import Scene from "../components/scene/Scene";
import SceneActionButtons from "../components/scene/SceneActionButtons";
import ContentGenerator from "../components/ContentGenerator";
import SceneWordByWordRenderer from "../components/scene/SceneWordByWordRenderer";

const AISceneGenerator = ({ darkMode = false, isNsfw = false }) => {
  const location = useLocation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [title, setTitle] = useState("AI Scene Generator");
  const { state, dispatch } = useScene();
  const [generationMode, setGenerationMode] = useState("open_ended");
  const [isContinueScene, setIsContinueScene] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  const [showSceneWordByWord, setShowSceneWordByWord] = useState(false);

  useEffect(() => {
    // console.log(darkMode, isNsfw);
  }, []);

  useEffect(() => {
    setTitle("AI Scene Generator");
    if (location.state && location.state.scene_title) {
      loadFromTitle(location.state.scene_title);
    } else {
      loadFromLocalStorage();
    }
  }, [location]);

  useEffect(() => {
    if (state.elements.length > 0 && state.title) {
      saveSceneToLocalStorage({
        title: state.title,
        premise: state.premise,
        elements: state.elements,
        genre: state.genre,
        isNsfw: state.isNsfw,
      });
    }
    if (state.title) {
      setTitle(state.title);
    } else {
      setTitle("AI Scene Generator");
    }
  }, [state.elements, state.premise, state.title]);

  const loadFromTitle = (title) => {
    const scene = loadSceneFromTitle(title);
    console.log(scene);
    if (scene) {
      loadScene(scene);
    }
  };

  const loadScene = (scene) => {
    if (scene) {
      dispatch({ type: "SET_TITLE", payload: scene.title });
      dispatch({ type: "SET_PREMISE", payload: scene.premise });
      dispatch({ type: "SET_ELEMENTS", payload: scene.elements });
      dispatch({ type: "SET_NSFW", payload: scene.isNsfw });
      return true;
    }
    return false;
  };

  const loadFromLocalStorage = () => {
    const lastScene = loadLastSceneFromLocalStorage(isNsfw);
    if (!loadScene(lastScene)) {
      generateNewPremise(isNsfw);
    }
  };

  const generateNewPremise = (isNsfw) => {
    let premiseData = getRandomScenePremise(isNsfw);
    dispatch({ type: "SET_PREMISE", payload: premiseData.premise });
    dispatch({ type: "SET_GENRE", payload: premiseData.genre });
  };

  const generateScene = async () => {
    setIsGenerating(true);
    const instruction = state.premise;
    try {
      let elements = await generateNewScene(instruction, isNsfw, (elements) => {
        dispatch({ type: "SET_ELEMENTS", payload: elements });
      });
      dispatch({ type: "SET_ELEMENTS", payload: elements });
      setShowSceneWordByWord(true);
      await generateSceneTitle();
    } catch (error) {
      console.error("Error generating scene:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const generateSceneTitle = async () => {
    setIsGeneratingTitle(true);
    try {
      const context = {
        elements: state.elements.slice(0, 2).join(" "),
        premise: state.premise,
      };
      const generatedTitle = await generateTitle(context);
      setTitle(generatedTitle);
      dispatch({ type: "SET_TITLE", payload: generatedTitle });
      dispatch({ type: "SET_NSFW", payload: isNsfw });
    } catch (error) {
      console.error("Error generating title:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsGeneratingTitle(false);
    }
  };

  const resetScene = () => {
    dispatch({ type: "RESET_SCENE" });
    generateNewPremise();
    setActiveElement(null);
    setTitle("AI Scene Generator");
  };

  const toggleGenerationMode = () => {
    setGenerationMode((prevMode) =>
      prevMode === "percentage_based" ? "open_ended" : "percentage_based"
    );
  };

  const toggleContinueScene = useCallback(() => {
    setIsContinueScene((prev) => !prev);
    // If turning on continue scene, scroll to the bottom
    if (!isContinueScene) {
      scrollToBottom();
    }
  }, [isContinueScene]);

  const scrollToBottom = () => {
    const elements = document.querySelectorAll(".scene-element");
    if (elements.length > 0) {
      const lastElement = elements[elements.length - 1];
      lastElement.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const handleSceneFinalize = async (continueElements) => {
    console.log(continueElements);
    if (continueElements) {
      dispatch({ type: "APPEND_ELEMENTS", payload: continueElements });
      setIsContinueScene(false);
      scrollToBottom();
    }
  };

  const handleElementAction = (action) => {
    if (activeElement !== null) {
      console.log(`Performing ${action} on element ${activeElement}`);
      if (action === "close") {
        setActiveElement(null);
      } else if (action === "delete") {
        dispatch({
          type: "SET_ELEMENTS",
          payload: state.elements.filter((_, index) => index !== activeElement),
        });
        setActiveElement(null);
      } else if (action === "edit") {
        // You might want to implement an edit functionality here
        console.log(
          `Edit functionality for element ${activeElement} not implemented yet`
        );
      } else if (action === "regenerate") {
        const elementToRegenerate = state.elements[activeElement];
        console.log(elementToRegenerate);
        // setIsGenerating(true);
        // generateNewScene(elementToRegenerate.content, (newElement) => {
        //   const updatedElements = [...state.elements];
        //   updatedElements[activeElement] = newElement[0]; // Assuming generateNewScene returns an array
        //   dispatch({
        //     type: "SET_ELEMENTS",
        //     payload: updatedElements,
        //   });
        //   setIsGenerating(false);
        // });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Scene Generator | Create Screenplay Scenes with AI</title>
        <meta
          name="description"
          content="Use our AI Scene Generator to create unique, engaging screenplay scenes with artificial intelligence. Generate creative premises and expand your screenplay effortlessly."
        />
      </Helmet>
      <div
        className={`min-h-screen flex flex-col ${
          darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-50"
        }`}
      >
        <header
          className={`sticky top-0 z-10 ${
            darkMode ? "bg-gray-800 text-gray-100" : "bg-white"
          } shadow-md transition-all duration-300 sm:py-1`}
        >
          <div className="container mx-auto px-4">
            <div className="hidden sm:block mb-4">
              <SceneActionButtons
                darkMode={darkMode}
                isNsfw={isNsfw}
                toggleContinueScene={toggleContinueScene}
                isContinueScene={isContinueScene}
                isGenerating={isGenerating}
                activeElement={activeElement}
                onElementAction={handleElementAction}
                resetScene={resetScene}
              />
            </div>
            <StoryTitle
              title={title}
              isGeneratingTitle={isGeneratingTitle}
              onGenerateTitle={() => {}}
              isCompact={false}
              darkMode={darkMode}
            />
          </div>
        </header>

        <main className="flex-grow container mx-auto px-2 py-2">
          <div className="max-w-4xl mx-auto">
            <div className="mb-2">
              <StoryDetails
                premise={state.premise}
                genre={state.genre}
                onUpdate={(newPremise) =>
                  dispatch({ type: "SET_PREMISE", payload: newPremise })
                }
                isFinal={state.elements.length > 0}
                darkMode={darkMode}
                isNsfw={isNsfw}
              />
              {state.elements.length === 0 && !isGenerating && (
                <button
                  onClick={generateScene}
                  className="mt-6 w-full bg-amber-500 hover:bg-amber-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Generate Scene
                </button>
              )}
            </div>

            {isGenerating && (
              <div className="flex justify-center my-8">
                <FiLoader className="animate-spin text-4xl text-amber-500" />
              </div>
            )}

            {showSceneWordByWord ? (
              <SceneWordByWordRenderer
                elements={state.elements}
                darkMode={darkMode}
                typingSpeed={50}
                dialogueTypingSpeed={100}
                dialoguePause={500}
                onComplete={() => {
                  setShowSceneWordByWord(false);
                }}
                // onWordRendered={() => {
                //   if (contentRef.current) {
                //     contentRef.current.scrollTop =
                //       contentRef.current.scrollHeight;
                //   }
                // }}
              />
            ) : (
              <Scene
                elements={state.elements}
                setActiveElement={setActiveElement}
                activeElement={activeElement}
                darkMode={darkMode}
                isNsfw={isNsfw}
              />
            )}
          </div>
        </main>

        {state.elements.length !== 0 && !showSceneWordByWord && (
          <footer
            className={`sticky bottom-0 ${
              darkMode
                ? "bg-gray-800 border-gray-700"
                : "bg-white border-gray-200"
            } border-t mt-2`}
          >
            <div className="flex-grow container mx-auto px-4 py-2 max-w-8xl">
              {/* Desktop Bottom Bar */}
              <div className="hidden sm:block">
                <ContentGenerator
                  onFinalize={handleSceneFinalize}
                  mode="continue_scene"
                  title="Add custom instructions to continue scene"
                  onSaveInstruction={() => {}}
                  generationMode={generationMode}
                  countProp={10}
                  isNsfw={isNsfw}
                />
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-between">
                {isContinueScene && (
                  <div className="w-full sm:w-auto mb-2 sm:mb-0">
                    <ContentGenerator
                      onFinalize={handleSceneFinalize}
                      mode="continue_scene"
                      title="Add custom instructions to continue scene"
                      onSaveInstruction={() => {}}
                      generationMode={generationMode}
                      countProp={10}
                      isNsfw={isNsfw}
                    />
                  </div>
                )}
                <div className="sm:hidden w-full sm:w-auto">
                  <SceneActionButtons
                    darkMode={darkMode}
                    isNsfw={isNsfw}
                    toggleContinueScene={toggleContinueScene}
                    isContinueScene={isContinueScene}
                    isGenerating={isGenerating}
                    activeElement={activeElement}
                    onElementAction={handleElementAction}
                    resetScene={resetScene}
                  />
                </div>
              </div>
            </div>
          </footer>
        )}
      </div>
    </>
  );
};

export default AISceneGenerator;
