import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiArrowRight, FiEdit3 } from "react-icons/fi";

// At the top of the file, add export
export const organizeStories = (stories) => {
  // Group stories by premise
  const premiseGroups = stories.reduce((acc, story) => {
    if (!story.premise) return acc;
    if (!acc[story.premise]) {
      acc[story.premise] = [];
    }
    acc[story.premise].push(story);
    return acc;
  }, {});

  // Group stories by genre
  const genreGroups = stories.reduce((acc, story) => {
    if (!story.genre) return acc;
    if (!acc[story.genre]) {
      acc[story.genre] = [];
    }
    acc[story.genre].push(story);
    return acc;
  }, {});

  // Convert to array format
  const byPremise = Object.entries(premiseGroups).map(([premise, stories]) => ({
    id: premise,
    premise,
    stories,
  }));

  const byGenre = Object.entries(genreGroups).map(([genre, stories]) => ({
    genre,
    stories,
  }));

  // Get featured category
  const featured = byGenre[0]
    ? {
        name: byGenre[0].genre,
        premises: byPremise.filter((p) =>
          p.stories.some((s) => s.genre === byGenre[0].genre)
        ),
      }
    : null;

  return { byPremise, byGenre, featured };
};

const StoryDashboard = ({
  stories = [],
  isCompact = false,
  darkMode = false,
}) => {
  const isNSFW = location.pathname.includes("nsfw");
  const [organizedStories, setOrganizedStories] = useState({
    byPremise: [],
    byGenre: [],
    featured: null,
  });

  useEffect(() => {
    const organized = organizeStories(stories);
    setOrganizedStories(organized);
  }, [stories]);

  const StoryCard = ({ story }) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    };

    return (
      <Link
        to={`/story/${story.id}`}
        className={`group flex flex-col p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-300 ${
          darkMode
            ? "bg-gray-800 hover:bg-gray-700"
            : "bg-white hover:bg-gray-50"
        }`}
      >
        <h4
          className={`font-semibold group-hover:text-blue-400 transition-colors ${
            darkMode ? "text-white" : "text-gray-800"
          }`}
        >
          {story.title}
        </h4>
        <div className="flex justify-between items-center mt-1">
          <p
            className={`text-sm ${
              darkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            by {story.author}
          </p>
          <span
            className={`text-xs ${
              darkMode ? "text-gray-400" : "text-gray-500"
            }`}
          >
            {story.createdAt ? formatDate(story.createdAt) : ""}
          </span>
        </div>
        {story.genre && (
          <span
            className={`text-xs mt-2 px-2 py-1 rounded-full self-start ${
              darkMode
                ? "bg-gray-700 text-gray-300"
                : "bg-gray-100 text-gray-500"
            }`}
          >
            {story.genre}
          </span>
        )}
      </Link>
    );
  };

  const SectionHeader = ({ title, viewAll }) => (
    <div className="flex justify-between items-center mb-4">
      <h2
        className={`text-2xl font-bold ${
          darkMode ? "text-white" : "text-black"
        }`}
      >
        {title}
      </h2>
      {viewAll && (
        <Link
          to={viewAll}
          className={`flex items-center ${
            darkMode
              ? "text-blue-400 hover:text-blue-300"
              : "text-blue-500 hover:text-blue-600"
          }`}
        >
          View All <FiArrowRight className="ml-1" />
        </Link>
      )}
    </div>
  );

  if (!stories.length) {
    return (
      <div
        className={`text-center py-8 ${
          darkMode ? "text-gray-400" : "text-gray-500"
        }`}
      >
        No stories available at the moment.
      </div>
    );
  }

  return (
    <div className={`space-y-12 ${isCompact ? "p-4" : "p-6"}`}>
      {/* Popular Premises Section */}
      {organizedStories.byPremise.length > 0 && (
        <section
          className={`p-6 rounded-xl ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <SectionHeader
            title="Popular Premises"
            viewAll={isNSFW ? "/premises/nsfw" : "/premises"}
          />
          <div className="space-y-6">
            {organizedStories.byPremise.slice(0, 2).map((premiseGroup) => (
              <div key={premiseGroup.id} className="space-y-3">
                <div className="flex items-center gap-2">
                  {/* <Link
                    to={`/ai-story-generator?new=true`}
                    state={{ premise: premiseGroup.premise }}
                    className={`p-1.5 rounded-full hover:bg-gray-100 transition-colors ${
                      darkMode
                        ? "text-gray-300 hover:bg-gray-700"
                        : "text-gray-600 hover:bg-gray-200"
                    }`}
                    title="Write a new story with this premise"
                  >
                    <FiEdit3 size={16} />
                  </Link> */}
                  <h3
                    className={`text-lg font-semibold truncate hover:whitespace-normal cursor-pointer ${
                      darkMode ? "text-white" : "text-black"
                    }`}
                  >
                    {premiseGroup.premise}
                  </h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {premiseGroup.stories.map((story) => (
                    <StoryCard key={story.id} story={story} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* Genres Section */}
      {organizedStories.byGenre.length > 0 && (
        <section
          className={`p-6 rounded-xl ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <SectionHeader title="Browse by Genre" viewAll="/genres" />
          <div className="space-y-6">
            {organizedStories.byGenre.slice(0, 2).map((genre) => (
              <div key={genre.genre} className="space-y-3">
                <h3
                  className={`text-lg font-semibold truncate hover:whitespace-normal cursor-pointer ${
                    darkMode ? "text-white" : "text-black"
                  }`}
                >
                  {genre.genre}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {genre.stories.map((story) => (
                    <StoryCard key={story.id} story={story} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* Featured Category Section */}
      {!isCompact && organizedStories.featured && (
        <section
          className={`p-6 rounded-xl ${
            darkMode ? "bg-gray-800/50" : "bg-gray-50"
          }`}
        >
          <SectionHeader
            title={organizedStories.featured.name}
            viewAll={`/categories/${organizedStories.featured.name.toLowerCase()}`}
          />
          <div className="space-y-6">
            {organizedStories.featured.premises
              .slice(0, 2)
              .map((premiseGroup) => (
                <div key={premiseGroup.id} className="space-y-3">
                  <h3
                    className={`text-lg font-semibold truncate hover:whitespace-normal cursor-pointer ${
                      darkMode ? "text-white" : "text-black"
                    }`}
                  >
                    {premiseGroup.premise}
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {premiseGroup.stories.map((story) => (
                      <StoryCard key={story.id} story={story} />
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default StoryDashboard;
