export const saveSceneToLocalStorage = (scene) => {
  // Add createdAt timestamp if it doesn't exist
  const sceneWithTimestamp = {
    ...scene,
    createdAt: scene.createdAt || new Date().toISOString(),
  };

  let scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  const title = sceneWithTimestamp.title.replace(/\s+/g, "_").toLowerCase();
  const sceneToSave = { ...sceneWithTimestamp, id: title };

  const sceneIndex = scenes.findIndex((s) => s.id === title);
  if (sceneIndex !== -1) {
    scenes[sceneIndex] = sceneToSave;
  } else {
    scenes.push(sceneToSave);
  }

  localStorage.setItem("scenes", JSON.stringify(scenes));
};

export const loadLastSceneFromLocalStorage = (isNsfw) => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  const filteredScenes = isNsfw
    ? scenes.filter((s) => s.isNsfw)
    : scenes.filter((s) => !s.isNsfw);
  return filteredScenes.length > 0
    ? filteredScenes[filteredScenes.length - 1]
    : null;
};

export const loadSceneFromTitle = (title) => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  return scenes.find((s) => s.title === title);
};

export const loadAllScenesFromLocalStorage = (isNsfw) => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  const filteredScenes = isNsfw
    ? scenes.filter((s) => s.isNsfw)
    : scenes.filter((s) => !s.isNsfw);
  return filteredScenes;
};

export const clearAllScenesFromLocalStorage = () => {
  localStorage.removeItem("scenes");
};

export const deleteSceneFromLocalStorage = (sceneId) => {
  const scenes = loadAllScenesFromLocalStorage();
  const updatedScenes = scenes.filter((scene) => scene.id !== sceneId);
  localStorage.setItem("scenes", JSON.stringify(updatedScenes));
};
