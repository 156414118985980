import React, { useState, useEffect } from "react";
import {
  FaPlay,
  FaSync,
  FaSmile,
  FaSadTear,
  FaAngry,
  FaCircle,
} from "react-icons/fa";
import { testVoice } from "../api/speech";

const emotions = [
  {
    id: "happy",
    icon: FaSmile,
    label: "Happy",
    color: "text-yellow-400",
    borderColor: "border-yellow-400",
    bgColor: "bg-yellow-400",
    levels: [
      { label: "cheerful", intensity: 1 },
      { label: "joyful", intensity: 2 },
      { label: "ecstatic", intensity: 3 },
    ],
  },
  {
    id: "sad",
    icon: FaSadTear,
    label: "Sad",
    color: "text-blue-400",
    borderColor: "border-blue-400",
    bgColor: "bg-blue-400",
    levels: [
      { label: "melancholic", intensity: 1 },
      { label: "sorrowful", intensity: 2 },
      { label: "devastated", intensity: 3 },
    ],
  },
  {
    id: "angry",
    icon: FaAngry,
    label: "Angry",
    color: "text-red-400",
    borderColor: "border-red-400",
    bgColor: "bg-red-400",
    levels: [
      { label: "annoyed", intensity: 1 },
      { label: "furious", intensity: 2 },
      { label: "enraged", intensity: 3 },
    ],
  },
];

const movieDialogues = [
  {
    id: 1,
    text: "It’s a beautiful day. Don’t let it get away.",
    intensity: 1,
    emotion: "sad",
    voice: "coral",
  },
  {
    id: 2,
    text: "Frankly, my dear, I don't give a damn.",
    intensity: 3,
    emotion: "angry",
    voice: "ash",
  },
  {
    id: 3,
    text: "I wish I could bake a cake filled with rainbows and smiles, and everyone would eat and be happy!",
    intensity: 2,
    emotion: "happy",
    voice: "sage",
  },
  {
    id: 4,
    text: "This is nice. I didn’t think I’d feel this good, but it’s kind of perfect, isn’t it?",
    intensity: 1,
    emotion: "happy",
    voice: "ballad",
  },
  {
    id: 5,
    text: "I'm going to make him an offer he can't refuse.",
    intensity: 2,
    emotion: "angry",
    voice: "ash",
  },
  {
    id: 6,
    text: "I don’t understand… why does it always have to end like this? It’s like no matter what I do, it’s never enough!",
    intensity: 3,
    emotion: "sad",
    voice: "ballad",
  },
];

const voices = [
  { id: "alloy", name: "Alloy" },
  { id: "ash", name: "Ash" },
  { id: "ballad", name: "Ballad" },
  { id: "coral", name: "Coral" },
  { id: "sage", name: "Sage" },
  { id: "verse", name: "Verse" },
];

const EmotionIntensityControl = ({
  dialogue,
  emotion,
  isSelected,
  onSelect,
}) => (
  <button
    onClick={() => onSelect(emotion.id)}
    className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all
      ${
        isSelected
          ? `${emotion.color} ${emotion.bgColor}/10 border ${emotion.borderColor}`
          : "text-gray-400 hover:bg-gray-700/30"
      }`}
  >
    <emotion.icon className={`text-lg ${isSelected ? "scale-110" : ""}`} />
    <span className="text-sm font-medium">{emotion.label}</span>
  </button>
);

const IntensitySlider = ({
  dialogue,
  emotion,
  currentLevel,
  onLevelChange,
}) => (
  <div className="w-full mt-4 p-4 rounded-lg bg-gray-700/30 border border-gray-700">
    <div className="flex justify-between text-xs mb-3">
      <span
        className={`${emotion.color} ${emotion.bgColor}/10 px-2 py-1 rounded transition-all`}
      >
        {emotion.levels[currentLevel - 1]?.label || "neutral"}
      </span>
    </div>
    <div className="relative h-1.5 bg-gray-700 rounded-full">
      <div
        className={`absolute h-full rounded-full transition-all ${emotion.bgColor}`}
        style={{
          width: `${(currentLevel / 3) * 100}%`,
        }}
      />
      <div className="relative flex justify-between px-1">
        {emotion.levels.map((level) => (
          <button
            key={level.intensity}
            onClick={() => onLevelChange(dialogue.id, level.intensity)}
            className={`w-4 h-4 -mt-1.5 rounded-full transition-all
              ${
                currentLevel >= level.intensity
                  ? `${emotion.bgColor} scale-100`
                  : "bg-gray-600 scale-75"
              } 
              hover:scale-100`}
            title={level.label}
          />
        ))}
      </div>
    </div>
  </div>
);

const getTextWithEmotion = (text, emotion, level) => {
  if (!emotion) return text;
  const selectedEmotion = emotions.find((e) => e.id === emotion);
  if (!selectedEmotion) return text;

  const emotionLevel = selectedEmotion.levels.find(
    (l) => l.intensity === level
  );
  //   const tagName = emotionLevel ? emotionLevel.label : emotion;
  const tagName = emotion + " intensity=" + level;
  return `<${tagName}>${text}</${tagName}>`;
};

const getCacheKey = (dialogueId, emotion, level) => {
  return `${dialogueId}-${emotion || "default"}-${level || 0}`;
};

const DialogueCard = ({ dialogue, playingId, onPlay: parentOnPlay }) => {
  const [selectedEmotion, setSelectedEmotion] = useState(dialogue.emotion);
  const [emotionLevel, setEmotionLevel] = useState(dialogue.intensity);
  const [selectedVoice, setSelectedVoice] = useState(dialogue.voice);
  const [progress, setProgress] = useState(0);
  const [audioElement, setAudioElement] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      if (audioElement) {
        audioElement.pause();
        URL.revokeObjectURL(audioElement.src);
      }
    };
  }, [audioElement]);

  const handleEmotionSelect = (emotionId) => {
    if (selectedEmotion === emotionId) {
      setEmotionLevel(emotionLevel === 0 ? 1 : 0);
    } else {
      setSelectedEmotion(emotionId);
      setEmotionLevel(1);
    }
  };

  const selectedEmotionData = emotions.find((e) => e.id === selectedEmotion);

  const handlePlay = async (dialogue, emotion, level, voice) => {
    try {
      setError(null);
      parentOnPlay(dialogue.id);
      setProgress(0);

      const textToSpeak = getTextWithEmotion(dialogue.text, emotion, level);
      const response = await testVoice(textToSpeak, voice);

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);

      if (audioElement) {
        audioElement.pause();
        URL.revokeObjectURL(audioElement.src);
      }

      const audio = new Audio(audioUrl);
      setAudioElement(audio);

      audio.addEventListener("timeupdate", () => {
        const percent = (audio.currentTime / audio.duration) * 100;
        setProgress(percent);
      });

      audio.addEventListener("ended", () => {
        parentOnPlay(null);
        setProgress(0);
      });

      audio.play();
    } catch (err) {
      setError(err.message);
      parentOnPlay(null);
      setProgress(0);
    }
  };

  return (
    <div className="relative bg-gray-800 rounded-xl border border-gray-700 overflow-hidden hover:border-purple-500/50 transition-colors duration-300">
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-4 w-full">
            <button
              onClick={() =>
                handlePlay(
                  dialogue,
                  selectedEmotion,
                  emotionLevel,
                  selectedVoice
                )
              }
              disabled={playingId !== null}
              className="group relative flex items-center gap-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white px-4 py-2 rounded-lg hover:from-purple-700 hover:to-pink-700 disabled:from-gray-600 disabled:to-gray-600 transition-all duration-300"
            >
              {playingId === dialogue.id ? (
                <FaSync className="animate-spin" />
              ) : (
                <FaPlay />
              )}
            </button>
            <select
              value={selectedVoice}
              onChange={(e) => setSelectedVoice(e.target.value)}
              className="bg-gray-700 text-gray-200 rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              {voices.map((voice) => (
                <option key={voice.id} value={voice.id}>
                  {voice.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="relative">
          <div className="absolute inset-0 bg-gray-700/30 rounded" />
          <div
            className="absolute inset-0 bg-purple-500/20 rounded transition-all duration-200"
            style={{
              width: `${progress}%`,
            }}
          />
          <p className="relative text-gray-300 mb-4 p-2">{dialogue.text}</p>
        </div>

        <div className="flex flex-wrap gap-2 mb-2">
          {emotions.map((emotion) => (
            <EmotionIntensityControl
              key={emotion.id}
              dialogue={dialogue}
              emotion={emotion}
              isSelected={selectedEmotion === emotion.id}
              onSelect={handleEmotionSelect}
            />
          ))}
        </div>

        {selectedEmotionData && emotionLevel > 0 && (
          <IntensitySlider
            dialogue={dialogue}
            emotion={selectedEmotionData}
            currentLevel={emotionLevel}
            onLevelChange={(_, level) => setEmotionLevel(level)}
          />
        )}

        <div className="mt-4 text-sm text-gray-400">
          {emotionLevel > 0 && selectedEmotionData && (
            <>
              Will speak in{" "}
              <span className={selectedEmotionData.color}>
                {selectedEmotionData.levels[emotionLevel - 1].label}
              </span>{" "}
              tone
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const VoiceTest = () => {
  const [audioCache, setAudioCache] = useState({});
  const [playingId, setPlayingId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cached = localStorage.getItem("audioCache");
    if (cached) {
      setAudioCache(JSON.parse(cached));
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-2">
            AI Voice Actor
          </h1>
          <p className="text-gray-400">
            You are the director who will direct dialogues with emotional
            intensity
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2">
          {movieDialogues.map((dialogue) => (
            <DialogueCard
              key={dialogue.id}
              dialogue={dialogue}
              playingId={playingId}
              onPlay={setPlayingId}
            />
          ))}
        </div>

        {error && (
          <div className="mt-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
            Error: {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceTest;
