import React from "react";
import { actors } from "./data/actors";

const ActorGrid = ({ selectedActor, onActorSelect }) => {
  return (
    <div className="grid grid-cols-3 gap-4 max-w-5xl mx-auto">
      {actors.map((actor) => (
        <button
          key={actor.id}
          onClick={() => onActorSelect(actor)}
          className={`flex items-start gap-4 p-4 rounded-xl transition-all text-left
            ${
              selectedActor?.id === actor.id
                ? "bg-purple-500/20 border border-purple-500"
                : "border border-gray-700 hover:border-purple-500/50 hover:bg-gray-800/50"
            }`}
        >
          <span className="text-4xl">{actor.avatar}</span>
          <div className="flex-1">
            <h3 className="text-lg font-semibold text-white mb-1">
              {actor.name}
            </h3>
            <p className="text-purple-400 text-sm mb-2">{actor.specialty}</p>
            <p className="text-gray-400 text-sm mb-2">
              Experience: {actor.experience}
            </p>
            <p className="text-gray-500 text-sm leading-relaxed">{actor.bio}</p>
          </div>
        </button>
      ))}
    </div>
  );
};

export default ActorGrid;
