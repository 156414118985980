import React, { useState, useEffect } from "react";
import axios from "axios";

const UsageReportView = () => {
  const [usageData, setUsageData] = useState({
    daily_data: [],
    ip_details: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsageData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/usage/daily`
        );
        setUsageData(response.data || { daily_data: [], ip_details: [] });
        setLoading(false);
      } catch (err) {
        setError("Failed to load usage data");
        setLoading(false);
      }
    };

    fetchUsageData();
  }, []);

  if (loading) return <div>Loading usage data...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!usageData?.daily_data?.length && !usageData?.ip_details?.length) {
    return <div>No usage data available</div>;
  }

  // Find the maximum calls across all IPs
  const maxCalls = Math.max(
    ...usageData.ip_details.map((detail) => detail.total_calls),
    1
  );

  return (
    <div className="usage-report-container p-4">
      <h1 className="text-2xl font-bold mb-6">Usage Report</h1>

      {/* Bar Graph */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Usage by IP Address</h2>
        <div className="flex items-end h-96 bg-gray-50 p-4 rounded-lg overflow-x-auto">
          {usageData.ip_details.map((detail) => (
            <div
              key={detail.ip}
              className="flex flex-col items-center mx-4 first:ml-0 last:mr-0"
              style={{
                minWidth: "120px",
              }}
            >
              <div className="relative w-full flex justify-center h-80">
                <div
                  className="bg-blue-500 hover:bg-blue-600 transition-all rounded-t w-20 relative group"
                  style={{
                    height: `${Math.max(
                      (detail.total_calls / maxCalls) * 100,
                      5
                    )}%`,
                  }}
                >
                  {/* Tooltip */}
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 text-white p-2 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                    <div>Total Calls: {detail.total_calls}</div>
                    {detail.function_calls?.map((func, idx) => (
                      <div key={idx}>
                        {func.function}: {func.count}
                      </div>
                    ))}
                  </div>

                  {/* Call count on top */}
                  <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 text-sm font-bold">
                    {detail.total_calls}
                  </div>
                </div>
              </div>
              <div className="mt-2 text-sm font-medium max-w-[120px] overflow-hidden text-ellipsis text-center">
                {detail.ip}
              </div>
              <div className="text-xs text-gray-500">{detail.last_access}</div>
            </div>
          ))}
        </div>
      </div>

      {/* IP Details Table */}
      <div className="usage-stats">
        <h2 className="text-xl font-bold mb-4">Detailed Statistics</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border">IP Address</th>
                <th className="px-4 py-2 border">Total Calls</th>
                <th className="px-4 py-2 border">Function Calls</th>
                <th className="px-4 py-2 border">Last Access</th>
              </tr>
            </thead>
            <tbody>
              {(usageData.ip_details || []).map((detail) => (
                <tr key={detail.ip} className="hover:bg-gray-50">
                  <td className="px-4 py-2 border">{detail.ip}</td>
                  <td className="px-4 py-2 border">{detail.total_calls}</td>
                  <td className="px-4 py-2 border">
                    <ul className="list-disc pl-4">
                      {(detail.function_calls || []).map((func, index) => (
                        <li key={index}>
                          {func.function}: {func.count}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="px-4 py-2 border">{detail.last_access}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsageReportView;
