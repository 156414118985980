import React from "react";
import { TbMessageCircle2 } from "react-icons/tb";
import { FaUser } from "react-icons/fa";

const SceneElement = ({
  element,
  index,
  setActiveElement,
  isActive,
  darkMode = false,
}) => {
  const handleClick = () => {
    setActiveElement(index);
  };

  const elementClasses = `my-4 transition-all duration-300 ${
    isActive
      ? `${
          darkMode ? "bg-purple-900/30" : "bg-purple-500/10"
        } p-4 rounded-lg border ${
          darkMode ? "border-purple-700/30" : "border-purple-500/20"
        }`
      : ""
  } cursor-pointer ${
    darkMode ? "hover:bg-purple-900/20" : "hover:bg-purple-500/5"
  } rounded-lg`;

  if (!element) return null;
  switch (element.type) {
    case "action":
      return (
        <div
          key={index}
          className={`${elementClasses} ${
            darkMode ? "text-gray-300" : "text-gray-600"
          }`}
          onClick={handleClick}
        >
          {element.description}
        </div>
      );
    case "dialogue":
      return (
        <div
          key={index}
          className={`${elementClasses} p-4`}
          onClick={handleClick}
        >
          <div className="flex gap-4">
            {/* Character Avatar Section */}
            <div className="flex-shrink-0 h-full">
              <div className="sticky top-4">
                <div className="w-12 h-12 rounded-full bg-gradient-to-br from-purple-600 to-pink-600 flex items-center justify-center border-2 border-purple-300 shadow-lg shadow-purple-500/20">
                  <FaUser className="text-white text-xl" />
                </div>
                <div
                  className={`mt-2 w-12 text-center text-xs font-bold ${
                    darkMode
                      ? "text-purple-300 bg-purple-950/50"
                      : "text-purple-600 bg-purple-100"
                  } py-1 rounded-md border ${
                    darkMode ? "border-purple-500/30" : "border-purple-300/30"
                  }`}
                >
                  {element.character}
                </div>
              </div>
            </div>

            {/* Dialogue Content */}
            <div className="flex-grow">
              <div className="relative">
                {/* Dialogue box with sharp angles */}
                <div
                  className={`${
                    darkMode
                      ? "bg-gradient-to-br from-gray-900/95 to-gray-800/95 border-purple-500/30"
                      : "bg-gradient-to-br from-white to-gray-50 border-purple-300/30"
                  } p-4 rounded-lg border-2 shadow-xl relative`}
                >
                  {/* Top accent line */}
                  <div className="absolute top-0 left-4 right-4 h-0.5 bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>

                  {/* Parenthetical */}
                  {element.parenthetical && (
                    <div
                      className={`italic text-sm mb-2 font-gaming ${
                        darkMode ? "text-pink-400/70" : "text-pink-500/70"
                      }`}
                    >
                      ({element.parenthetical})
                    </div>
                  )}

                  {/* Main dialogue text */}
                  <div
                    className={`leading-relaxed text-lg ${
                      darkMode ? "text-gray-100" : "text-gray-800"
                    }`}
                  >
                    {element.line}
                  </div>

                  {/* Bottom accent line */}
                  <div className="absolute bottom-0 left-4 right-4 h-0.5 bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>

                  {/* Decorative corner accents */}
                  <div
                    className={`absolute top-0 left-0 w-2 h-2 border-t-2 border-l-2 ${
                      darkMode ? "border-purple-500/50" : "border-purple-300/50"
                    }`}
                  ></div>
                  <div
                    className={`absolute top-0 right-0 w-2 h-2 border-t-2 border-r-2 ${
                      darkMode ? "border-purple-500/50" : "border-purple-300/50"
                    }`}
                  ></div>
                  <div
                    className={`absolute bottom-0 left-0 w-2 h-2 border-b-2 border-l-2 ${
                      darkMode ? "border-purple-500/50" : "border-purple-300/50"
                    }`}
                  ></div>
                  <div
                    className={`absolute bottom-0 right-0 w-2 h-2 border-b-2 border-r-2 ${
                      darkMode ? "border-purple-500/50" : "border-purple-300/50"
                    }`}
                  ></div>
                </div>

                {/* Triangle pointer */}
                <div
                  className={`absolute left-[-12px] top-6 w-4 h-4 transform rotate-45 ${
                    darkMode
                      ? "bg-gradient-to-br from-gray-900/95 to-gray-800/95 border-purple-500/30"
                      : "bg-gradient-to-br from-white to-gray-50 border-purple-300/30"
                  } border-l-2 border-b-2`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      );
    case "internal_monologue":
      return (
        <div
          key={index}
          className={`${elementClasses} p-4`}
          onClick={handleClick}
        >
          <div className="flex gap-4">
            {/* Character Avatar Section */}
            <div className="flex-shrink-0 h-full">
              <div
                className={`sticky top-4 w-12 h-12 rounded-full ${
                  darkMode
                    ? "bg-gradient-to-br from-gray-700/50 to-gray-600/50 border-gray-600/20"
                    : "bg-gradient-to-br from-gray-200 to-gray-300 border-gray-300/20"
                } flex items-center justify-center border`}
              >
                <FaUser
                  className={`${
                    darkMode ? "text-gray-400/70" : "text-gray-500/70"
                  } text-xl`}
                />
              </div>
              <div
                className={`mt-2 w-12 text-center text-xs font-bold ${
                  darkMode
                    ? "text-purple-300 bg-purple-950/50"
                    : "text-purple-600 bg-purple-100"
                } py-1 rounded-md border ${
                  darkMode ? "border-purple-500/30" : "border-purple-300/30"
                }`}
              >
                {element.character}
              </div>
            </div>

            {/* Internal Monologue Content */}
            <div className="flex-grow">
              <div
                className={`italic ${
                  darkMode
                    ? "text-gray-400 bg-gray-800/50 border-gray-700/30"
                    : "text-gray-600 bg-gray-100/50 border-gray-300/30"
                } p-3 rounded-lg border`}
              >
                {element.description || element.line}
              </div>
            </div>
          </div>
        </div>
      );
    case "transition":
      return (
        <div
          key={index}
          className={`${elementClasses} text-right ${
            darkMode ? "text-pink-500/70" : "text-pink-600/70"
          } uppercase font-bold tracking-wider`}
          onClick={handleClick}
        >
          {element.description}
        </div>
      );
    default:
      return null;
  }
};

export default SceneElement;
