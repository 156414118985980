import React, { useState, useEffect, useRef } from "react";

const WordByWordRenderer = ({
  paragraphs,
  typingSpeed = 50,
  onWordRendered,
  onFinished,
}) => {
  const [displayedContent, setDisplayedContent] = useState([]);
  const currentParagraphIndexRef = useRef(0);
  const currentWordIndexRef = useRef(0);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    const typeNextWord = () => {
      if (currentParagraphIndexRef.current < paragraphs.length) {
        const currentParagraph = paragraphs[currentParagraphIndexRef.current];
        const words = currentParagraph.split(" ");

        if (currentWordIndexRef.current < words.length) {
          setDisplayedContent((prev) => {
            const newContent = [...prev];
            if (!newContent[currentParagraphIndexRef.current]) {
              newContent[currentParagraphIndexRef.current] = "";
            }

            // Get the word we want to add
            const wordToAdd = words[currentWordIndexRef.current];

            // Create the complete text that should be in this paragraph up to this word
            const expectedText =
              words.slice(0, currentWordIndexRef.current + 1).join(" ") + " ";

            // Set the paragraph text to exactly what we expect it to be
            newContent[currentParagraphIndexRef.current] = expectedText;

            return newContent;
          });

          currentWordIndexRef.current++;

          if (onWordRendered) {
            onWordRendered();
          }
        } else {
          currentParagraphIndexRef.current++;
          currentWordIndexRef.current = 0;
        }

        typingTimeoutRef.current = setTimeout(typeNextWord, typingSpeed);
      } else {
        // All content has been rendered
        if (onFinished) {
          onFinished();
        }
      }
    };

    // Clear any existing timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typeNextWord();

    // Cleanup function
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [paragraphs, onWordRendered, onFinished, typingSpeed]);

  return (
    <div>
      {displayedContent.map((paragraph, index) => (
        <p
          key={index}
          className="text-base sm:text-lg leading-relaxed text-gray-700 font-sans italic bg-white p-2 rounded shadow-sm"
        >
          {paragraph}
        </p>
      ))}
    </div>
  );
};

export default WordByWordRenderer;
