import React, { useState, useEffect } from "react";
import {
  getApiCallsHistory,
  rerunApiCall,
  clearApiCallsHistory,
} from "../api/apiTest";
import { FaSync, FaTrash } from "react-icons/fa";

const APITest = () => {
  const [apiCalls, setApiCalls] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    fetchApiCalls();
  }, []);

  const fetchApiCalls = async () => {
    try {
      const history = await getApiCallsHistory();
      // Sort the history array by timestamp in descending order
      history.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setApiCalls(history);
    } catch (error) {
      console.error("Error fetching API calls:", error);
      setError("Failed to fetch API calls history");
    }
  };

  const handleCallSelect = (call) => {
    setSelectedCall(call);
    setResponse(null);
    setError(null);
  };

  const handleRerun = async () => {
    if (!selectedCall) return;

    setIsProcessing(true);
    setError(null);
    setResponse(null);

    try {
      const result = await rerunApiCall(
        selectedCall.route,
        selectedCall.params
      );
      setResponse(result);
    } catch (error) {
      console.error("Error re-running API call:", error);
      setError("An error occurred while re-running the API call.");
    } finally {
      setIsProcessing(false);
    }
  };

  const wrapParagraphs = (text) => {
    if (typeof text !== "string") return text;
    return text.split("\n").map((paragraph, index) => (
      <p key={index} className="mb-2">
        {paragraph}
      </p>
    ));
  };

  const formatJsonWithParagraphs = (obj) => {
    if (typeof obj !== "object") return obj;

    const formatted = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key === "paragraphs" || key === "text") {
        formatted[key] = wrapParagraphs(value);
      } else if (typeof value === "object") {
        formatted[key] = formatJsonWithParagraphs(value);
      } else {
        formatted[key] = value;
      }
    }
    return formatted;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleClearHistory = async () => {
    try {
      await clearApiCallsHistory();
      setApiCalls([]);
      setSelectedCall(null);
      setResponse(null);
      setError(null);
    } catch (error) {
      console.error("Error clearing API calls history:", error);
      setError("Failed to clear API calls history");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">API Test</h1>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/3 lg:pr-4 mb-4 lg:mb-0">
          <div className="flex justify-between items-center mb-2 ">
            <h2 className="text-xl font-semibold">API Calls History</h2>
            <div>
              <button
                onClick={fetchApiCalls}
                className="p-2 rounded hover:bg-gray-200 mr-2"
                title="Refresh"
              >
                <FaSync />
              </button>
              <button
                onClick={handleClearHistory}
                className="p-2 rounded hover:bg-gray-200 text-red-500"
                title="Clear History"
              >
                <FaTrash />
              </button>
            </div>
          </div>
          <ul className="bg-gray-100 rounded p-2 max-h-[25vh] lg:max-h-[calc(100vh-200px)] overflow-y-auto">
            {apiCalls.map((call, index) => (
              <li
                key={index}
                className={`cursor-pointer p-2 hover:bg-gray-200 ${
                  selectedCall === call ? "bg-gray-300" : ""
                }`}
                onClick={() => handleCallSelect(call)}
              >
                <div className="font-semibold">{call.route}</div>
                <div className="text-sm text-gray-600">
                  {formatDate(call.timestamp)}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full lg:w-2/3">
          {selectedCall && (
            <div>
              <h2 className="text-xl font-semibold mb-2">Selected API Call</h2>
              <div className="bg-gray-100 p-2 rounded mb-4 max-h-[50vh] lg:max-h-[calc(100vh-400px)] overflow-y-auto">
                <h3 className="font-semibold">Route:</h3>
                <p>{selectedCall.route}</p>
                <h3 className="font-semibold mt-2">Params:</h3>
                <pre className="whitespace-pre-wrap overflow-x-auto">
                  {JSON.stringify(
                    formatJsonWithParagraphs(selectedCall.params),
                    null,
                    2
                  )}
                </pre>
                <h3 className="font-semibold mt-2">Original Response:</h3>
                {selectedCall.response && (
                  <pre className="whitespace-pre-wrap overflow-x-auto">
                    {JSON.stringify(
                      formatJsonWithParagraphs(selectedCall.response),
                      null,
                      2
                    )}
                  </pre>
                )}
              </div>
              <button
                className={`w-full lg:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${
                  isProcessing ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleRerun}
                disabled={isProcessing}
              >
                {isProcessing ? "Processing..." : "Re-run API Call"}
              </button>
              {error && (
                <div className="mt-4 text-red-500">
                  <p>{error}</p>
                </div>
              )}
              {response && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-2">New Response</h3>
                  <div className="bg-gray-100 p-2 rounded max-h-[50vh] lg:max-h-[calc(100vh-400px)] overflow-y-auto">
                    <pre className="whitespace-pre-wrap overflow-x-auto">
                      {JSON.stringify(
                        formatJsonWithParagraphs(response),
                        null,
                        2
                      )}
                    </pre>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default APITest;
