import React, { useState, useEffect } from "react";
import { getDialogues, createDialogue, deleteDialogue } from "../../api/speech";
import DialogueFormModal from "./DialogueFormModal";
import { FaTrash } from "react-icons/fa";

const DialogueGrid = ({ onDialogueSelect, isAdmin = false }) => {
  const [dialogues, setDialogues] = useState({ recent: [], older: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchDialogues();
  }, []);

  const fetchDialogues = async () => {
    try {
      const data = await getDialogues();
      const now = new Date();
      const oneDayAgo = new Date(now - 24 * 60 * 60 * 1000);

      // First sort dialogues with timestamps
      const sortedDialogues = data.sort((a, b) => {
        // If either dialogue lacks created_at, it goes to the end
        if (!a.created_at) return 1;
        if (!b.created_at) return -1;
        return new Date(b.created_at) - new Date(a.created_at);
      });

      const recent = sortedDialogues.filter(
        (d) => d.created_at && new Date(d.created_at) >= oneDayAgo
      );
      const older = sortedDialogues.filter(
        (d) => !d.created_at || new Date(d.created_at) < oneDayAgo
      );

      setDialogues({ recent, older });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDialogue = async (dialogueData) => {
    try {
      await createDialogue(dialogueData);
      await fetchDialogues(); // Refresh the list
      setIsModalOpen(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteDialogue = async (dialogueId, e) => {
    e.stopPropagation(); // Prevent dialogue selection when clicking delete

    if (!window.confirm("Are you sure you want to delete this dialogue?")) {
      return;
    }

    try {
      await deleteDialogue(dialogueId);
      await fetchDialogues(); // Refresh the list
    } catch (err) {
      setError(err.message);
    }
  };

  const DialogueCard = ({ dialogue }) => (
    <div
      key={dialogue.id}
      className="bg-gray-800 p-4 rounded-lg cursor-pointer hover:bg-gray-700 transition-colors relative group"
      onClick={() => onDialogueSelect(dialogue)}
    >
      <div className="flex justify-between items-start mb-3">
        <h3 className="text-white font-medium">{dialogue.scene}</h3>
        <div className="flex items-center gap-2">
          <span className="px-2 py-1 rounded-full bg-purple-500/20 text-purple-400 text-xs">
            {dialogue.mood}
          </span>
          {isAdmin && (
            <button
              onClick={(e) => handleDeleteDialogue(dialogue.id, e)}
              className="p-2 text-gray-400 hover:text-red-500"
              title="Delete dialogue"
            >
              <FaTrash size={14} />
            </button>
          )}
        </div>
      </div>
      <p className="text-sm text-gray-400 mb-4">{dialogue.context}</p>
      <p className="text-base text-gray-300 mb-4 italic">
        <span className="text-purple-400">"</span>
        {dialogue.dialogue}
        <span className="text-purple-400">"</span>
      </p>
      {dialogue.created_at && (
        <div className="text-xs text-gray-500">
          {new Date(dialogue.created_at).toLocaleDateString()}
        </div>
      )}
    </div>
  );

  if (loading) {
    return <div className="text-center p-4">Loading dialogues...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-4">
        <h2 className="text-xl text-white font-bold">Dialogues</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-500 transition-colors"
        >
          Add New Dialogue
        </button>
      </div>

      {/* Recent Dialogues Section */}
      <div className="p-4">
        <h3 className="text-lg text-white font-semibold mb-3">
          Recent Dialogues
        </h3>
        <div className="h-[250px] overflow-y-auto bg-gray-900/50 rounded-lg p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {dialogues.recent.map((dialogue) => (
              <DialogueCard key={dialogue.id} dialogue={dialogue} />
            ))}
            {dialogues.recent.length === 0 && (
              <p className="text-gray-500 col-span-full text-center">
                No recent dialogues
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Older Dialogues Section */}
      <div className="p-4">
        <h3 className="text-lg text-white font-semibold mb-3">
          Older Dialogues
        </h3>
        <div className="h-[200px] overflow-y-auto bg-gray-900/50 rounded-lg p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {dialogues.older.map((dialogue) => (
              <DialogueCard key={dialogue.id} dialogue={dialogue} />
            ))}
            {dialogues.older.length === 0 && (
              <p className="text-gray-500 col-span-full text-center">
                No older dialogues
              </p>
            )}
          </div>
        </div>
      </div>

      <DialogueFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmitDialogue}
      />
    </div>
  );
};

export default DialogueGrid;
