import React, { useState, useEffect } from "react";
import { recordTake, clearCache } from "../api/speech";
import DirectorFeedback from "../components/voice/DirectorFeedback";
import ActorGrid from "../components/voice/ActorGrid";
import ActorStrip from "../components/voice/ActorStrip";
import DialogueGrid from "../components/voice/DialogueGrid";
import RehearsalArea from "../components/voice/RehearsalArea";
import { actors } from "../components/voice/data/actors";

const VoiceActor = () => {
  const [selectedActor, setSelectedActor] = useState();
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [currentStep, setCurrentStep] = useState("casting");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [takes, setTakes] = useState(0);
  const [progress, setProgress] = useState(0);
  const [audioElement, setAudioElement] = useState(null);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleActorSelect = (actor) => {
    setSelectedActor(actor);
    setCurrentStep("dialogue");
  };

  const handleDialogueSelect = (dialogue) => {
    setSelectedDialogue(dialogue);
    setCurrentStep("rehearsal");
  };

  const handlePlayTake = async () => {
    try {
      setError(null);
      setIsPlaying(true);
      setProgress(0);
      setIsProcessing(true);

      const response = await recordTake(
        selectedDialogue.id,
        selectedDialogue.dialogue,
        selectedDialogue.scene,
        selectedDialogue.mood,
        selectedActor.voice
      );

      setIsProcessing(false);

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);

      if (audioElement) {
        audioElement.pause();
        URL.revokeObjectURL(audioElement.src);
      }

      const audio = new Audio(audioUrl);
      setAudioElement(audio);

      audio.addEventListener("timeupdate", () => {
        const percent = (audio.currentTime / audio.duration) * 100;
        setProgress(percent);
      });

      audio.addEventListener("ended", () => {
        setIsPlaying(false);
        setProgress(0);
        setTakes((prev) => prev + 1);
      });

      audio.play();
    } catch (err) {
      setError(err.message);
      setIsPlaying(false);
      setProgress(0);
      setIsProcessing(false);
    }
  };

  const handleCancelTake = () => {
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      setIsPlaying(false);
      setProgress(0);
    }
  };

  const handleBack = () => {
    handleCancelTake();
    setCurrentStep("dialogue");
  };

  useEffect(() => {
    // scroll to the top
    window.scrollTo(0, 0);
    setSelectedActor(actors[Math.floor(Math.random() * actors.length)]);
    setCurrentStep("dialogue");
    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleCancelTake();
      }
    };

    // Handle page unload/navigation
    const handleBeforeUnload = () => {
      handleCancelTake();
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleCancelTake();
    };
  }, []); // Empty dependency array since handleCancelTake is stable

  useEffect(() => {
    return () => {
      if (audioElement) {
        audioElement.pause();
        URL.revokeObjectURL(audioElement.src);
      }
    };
  }, [audioElement]);

  const resetSession = () => {
    setSelectedActor(null);
    setSelectedDialogue(null);
    setCurrentStep("casting");
    setTakes(0);
  };

  const handleClearCache = async () => {
    try {
      setIsProcessing(true);
      setError(null);

      await clearCache(selectedDialogue.id, selectedActor.voice);

      // Clear local audio state
      if (audioElement) {
        audioElement.pause();
        URL.revokeObjectURL(audioElement.src);
      }
      setAudioElement(null);
      setProgress(0);
      setIsPlaying(false);
      setTakes(0);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setIsAdmin(queryParams.get("admin") === "1");
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      <header className="flex-none p-4 border-b border-gray-800">
        <h1 className="text-xl sm:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 text-center">
          AI Voice Acting Studio
        </h1>
      </header>

      <div className="flex-none border-b border-gray-800">
        {currentStep === "rehearsal" ? (
          <ActorStrip
            selectedActor={selectedActor}
            onActorSelect={handleActorSelect}
          />
        ) : (
          <>
            <div className="md:hidden">
              <ActorStrip
                selectedActor={selectedActor}
                onActorSelect={handleActorSelect}
              />
            </div>
            <div className="hidden md:block p-6">
              <ActorGrid
                selectedActor={selectedActor}
                onActorSelect={handleActorSelect}
              />
            </div>
          </>
        )}
      </div>

      <div className="flex-1 min-h-0">
        {selectedActor &&
          (currentStep === "dialogue" ? (
            <DialogueGrid
              onDialogueSelect={handleDialogueSelect}
              isAdmin={isAdmin}
            />
          ) : (
            currentStep === "rehearsal" && (
              <RehearsalArea
                selectedActor={selectedActor}
                selectedDialogue={selectedDialogue}
                isPlaying={isPlaying}
                isProcessing={isProcessing}
                progress={progress}
                error={error}
                onBack={handleBack}
                onPlayTake={handlePlayTake}
                onCancelTake={handleCancelTake}
                onClearCache={handleClearCache}
              />
            )
          ))}
      </div>
    </div>
  );
};

export default VoiceActor;
