import React from "react";
import { useStory } from "../context/StoryContext";

const StoryProgressBar = ({ isCompact, darkMode = false }) => {
  const { state } = useStory();
  const progress = state.storyProgress || 0;

  return (
    <div className="relative w-full mb-4">
      <div
        className={`absolute top-3 right-0 text-xs ${
          darkMode ? "text-white" : "text-gray-500"
        } mb-1 transition-all duration-300 ${
          isCompact ? "hidden sm:block" : ""
        }`}
      >
        {progress}% Complete
      </div>
      <div
        className={`w-full bg-gray-200 rounded-full overflow-hidden ${
          isCompact ? "h-1.5" : "h-2.5"
        } transition-all duration-300`}
      >
        <div
          className="bg-amber-600 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${progress}%`, height: "100%" }}
        ></div>
      </div>
    </div>
  );
};

export default StoryProgressBar;
