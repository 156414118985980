import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStoryById } from "../api/stories";

const PublishedStoryView = () => {
  const { id } = useParams();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const fetchedStory = await getStoryById(id);
        setStory(fetchedStory);
        setLoading(false);
      } catch (err) {
        setError("Failed to load the story. Please try again.");
        setLoading(false);
      }
    };

    fetchStory();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!story) return <div>Story not found.</div>;

  return (
    <div className="max-w-3xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-2">{story.title}</h1>

      <div className="mb-6 text-gray-600">
        <p className="text-lg">by {story.author}</p>
        {story.premise && (
          <p className="mt-2 italic">Premise: {story.premise}</p>
        )}
      </div>

      <div className="prose">
        {story.paragraphs.map((paragraph, index) => (
          <p key={index} className="mb-4">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default PublishedStoryView;
