import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import {
  BsPencilFill,
  BsCameraReelsFill,
  BsStar,
  BsLightningFill,
  BsBook,
  BsHandThumbsUp,
  BsStars,
  BsFileText,
  BsVolumeUp,
} from "react-icons/bs";
import { getLandingPageHelmet } from "../utils/helmetUtils";
import StoryDashboard from "../components/StoryDashboard";

const LandingPage = () => {
  const history = useHistory();
  const [currentPrompt, setCurrentPrompt] = useState(0);

  const prompts = [
    "Scanning story elements...",
    "Crafting narratives...",
    "Enhancing characters...",
    "Ready to write.",
  ];

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentPrompt((prev) => (prev + 1) % prompts.length);
    }, 2000);

    return () => clearInterval(textInterval);
  }, []);

  const handleStartWriting = () => {
    history.push("/ai-story-generator");
  };

  const handleViewSamples = () => {
    history.push("/stories");
  };

  const services = [
    {
      icon: <BsBook className="w-12 h-12 mb-4 text-cyan-400" />,
      title: "Story Writing",
      description:
        "From enchanting short stories to complete novels. I'll help you create compelling narratives with unforgettable characters and rich plots.",
      features: [
        "Character Development",
        "Plot Structure",
        "World Building",
        "Multiple Genres",
      ],
      buttonText: "Start Your Story",
      route: "/ai-story-generator",
    },
    {
      icon: <BsCameraReelsFill className="w-12 h-12 mb-4 text-cyan-400" />,
      title: "Screenplay Writing",
      description:
        "Transform your story into a professionally formatted screenplay, complete with engaging dialogue and vivid scene descriptions.",
      features: [
        "Industry Standard Format",
        "Scene Direction",
        "Dialogue Enhancement",
        "Visual Storytelling",
      ],
      buttonText: "Watch Demo",
      route: "/scene-word-demo",
    },
    {
      icon: <BsVolumeUp className="w-12 h-12 mb-4 text-cyan-400" />,
      title: "AI Voice Actor",
      description:
        "Bring your dialogues to life with emotionally intelligent AI voices. Direct the perfect performance with customizable emotions and intensities.",
      features: [
        "Multiple Voice Options",
        "Emotional Control",
        "Intensity Levels",
        "Real-time Preview",
      ],
      buttonText: "Try Voice Actor Studio Now",
      route: "/voice/actor",
    },
    {
      icon: <BsFileText className="w-12 h-12 mb-4 text-cyan-400" />,
      title: "Report Writing",
      description:
        "Professional and concise reports for business, academic, or technical purposes. Clear, structured, and impactful documentation.",
      features: [
        "Data Analysis",
        "Clear Structure",
        "Technical Accuracy",
        "Executive Summaries",
      ],
      buttonText: "Coming Soon",
      disabled: true,
    },
  ];

  const handleServiceClick = (service) => {
    if (!service.disabled && service.route) {
      history.push(service.route);
    }
  };

  const helmetData = getLandingPageHelmet();

  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" content={helmetData.description} />
        <meta name="keywords" content={helmetData.keywords} />
        <link rel="canonical" href={helmetData.canonicalUrl} />
        <meta name="robots" content={helmetData.robots} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={helmetData.canonicalUrl} />
        <meta property="og:title" content={helmetData.title} />
        <meta property="og:description" content={helmetData.description} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={helmetData.canonicalUrl} />
        <meta property="twitter:title" content={helmetData.title} />
        <meta property="twitter:description" content={helmetData.description} />
      </Helmet>

      <div className="min-h-screen bg-gray-950 text-cyan-400">
        {/* Hero Section */}
        <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-b from-indigo-950 via-gray-950 to-slate-950"></div>
            <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(56,189,248,0.05)_50%,transparent_75%)] bg-[length:100%_100%]"></div>
            <div className="absolute inset-0 bg-[linear-gradient(135deg,transparent_25%,rgba(139,92,246,0.05)_50%,transparent_75%)] bg-[length:100%_100%]"></div>
          </div>

          <div className="relative z-10 text-center max-w-5xl mx-auto px-4">
            <div className="mb-8 relative">
              <div className="w-24 h-24 mx-auto relative">
                <div className="absolute inset-0 bg-cyan-500/20 rounded-full animate-pulse"></div>
                <div
                  className="absolute inset-2 bg-cyan-500/40 rounded-full animate-pulse"
                  style={{ animationDelay: "0.2s" }}
                ></div>
                <div
                  className="absolute inset-4 bg-cyan-500/60 rounded-full animate-pulse"
                  style={{ animationDelay: "0.4s" }}
                ></div>
                <div className="absolute inset-0 m-auto flex items-center justify-center">
                  <BsStar className="w-8 h-8 text-cyan-400 absolute" />
                  <BsLightningFill className="w-8 h-8 text-cyan-400 absolute rotate-45" />
                </div>
              </div>
            </div>

            <h1 className="text-3xl sm:text-5xl md:text-7xl font-bold mb-4 bg-gradient-to-r from-slate-100 to-slate-300 text-transparent bg-clip-text">
              <span className="inline-flex items-center justify-center">
                let me
                <span className="mx-2 sm:mx-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
                  WRITE
                </span>
                for you
              </span>
            </h1>
            <div className="h-8 mb-6 sm:mb-8">
              <p className="text-lg sm:text-xl md:text-2xl text-cyan-400 font-mono">
                {prompts[currentPrompt]}
              </p>
            </div>
            <p className="text-lg sm:text-xl md:text-2xl mb-8 sm:mb-12 text-slate-300 font-light max-w-3xl mx-auto px-4">
              Write beautifully with AI. Your ideas, naturally expressed.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
              <button
                onClick={handleStartWriting}
                className="group bg-cyan-950 text-cyan-400 font-mono py-4 px-8 rounded-lg text-lg transform transition-all hover:scale-105 hover:bg-cyan-900 border border-cyan-800 flex items-center gap-2"
              >
                Start Writing
                <BsStars className="w-5 h-5 group-hover:rotate-180 transition-transform" />
              </button>
              <button
                onClick={handleViewSamples}
                className="text-cyan-400 border border-cyan-800 font-mono py-4 px-8 rounded-lg text-lg transition-all hover:bg-cyan-950"
              >
                Read Stories
              </button>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="py-32 px-4 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-cyan-950/20 to-gray-950"></div>
          <div className="max-w-6xl mx-auto relative">
            <div className="text-center mb-20">
              <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-slate-100 to-slate-300 text-transparent bg-clip-text">
                Writing Services
                <span className="block text-xl mt-4 text-cyan-400 font-mono">
                  Choose Your Perfect Writing Solution
                </span>
              </h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="group bg-gray-950/50 backdrop-blur-xl rounded-lg p-8 transform transition-all duration-300 hover:-translate-y-2 border border-cyan-900/50 hover:border-cyan-400/50"
                >
                  <div className="text-center">
                    <div className="inline-block p-4 rounded-lg bg-cyan-950/50 group-hover:bg-cyan-900/50 transition-colors mb-6">
                      {service.icon}
                    </div>
                    <h3 className="text-2xl mb-4 bg-gradient-to-r from-slate-100 to-slate-200 text-transparent bg-clip-text font-bold">
                      {service.title}
                    </h3>
                    <p className="text-cyan-100 mb-8 leading-relaxed">
                      {service.description}
                    </p>
                    <div className="mb-8 text-left">
                      {service.features.map((feature, i) => (
                        <div key={i} className="flex items-center gap-2 mb-2">
                          <BsHandThumbsUp className="w-4 h-4 text-cyan-400" />
                          <span className="text-cyan-200">{feature}</span>
                        </div>
                      ))}
                    </div>
                    <button
                      className={`bg-cyan-950/50 text-cyan-400 font-mono py-3 px-6 rounded-lg transition-all ${
                        service.disabled
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-cyan-900/50 border border-cyan-800/50 hover:border-cyan-400/50"
                      }`}
                      disabled={service.disabled}
                      onClick={() => handleServiceClick(service)}
                    >
                      {service.buttonText}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-12 text-center">
              <p className="text-cyan-300 mb-4">
                Looking for our adult content writing assistant?
              </p>
              <button
                onClick={() => history.push("/nsfw")}
                className="text-cyan-400 border border-cyan-800 font-mono py-2 px-4 rounded-lg text-sm transition-all hover:bg-cyan-950"
              >
                Adult Content Writing →
              </button>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="relative py-32 px-4 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-cyan-950/10 to-gray-950"></div>
          <div className="max-w-4xl mx-auto text-center relative">
            <div className="mb-8">
              <BsPencilFill className="w-12 h-12 text-cyan-400 mx-auto animate-pulse" />
            </div>
            <h2 className="text-4xl font-bold mb-6 text-white">
              Ready to Tell Your Story?
            </h2>
            <p className="text-xl text-cyan-200 mb-12 max-w-2xl mx-auto font-light">
              Let's work together to bring your vision to life. Professional
              writing services with an AI advantage.
            </p>
            <button
              onClick={handleStartWriting}
              className="group bg-cyan-950 text-cyan-400 font-mono py-4 px-8 rounded-lg text-lg transform transition-all hover:scale-105 hover:bg-cyan-900 border border-cyan-800 flex items-center gap-2 mx-auto"
            >
              Begin Your Story
              <BsStars className="w-5 h-5 group-hover:rotate-180 transition-transform" />
            </button>
          </div>
        </div>

        {/* Add the compact dashboard */}
        {/* <section className="max-w-7xl mx-auto py-12">
          <StoryDashboard isCompact={true} />
        </section> */}
      </div>
    </>
  );
};

export default LandingPage;
