import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Tabs from "../components/Tabs";
import StoryDashboard from "../components/StoryDashboard";
import StoryList from "../components/StoryList";
import { getPublishedStories } from "../api/stories";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import {
  loadAllStoriesFromLocalStorage,
  clearAllStoriesFromLocalStorage,
  deleteStoryFromLocalStorage,
} from "../utils/storageUtils";
import { useStory } from "../context/StoryContext"; // Add this import
import { getStoriesPageHelmet } from "../utils/helmetUtils";

const StoriesPage = ({ darkMode = false, isNsfw = false }) => {
  const [activeTab, setActiveTab] = useState("public");
  const [publicStories, setPublicStories] = useState([]);
  const [privateStories, setPrivateStories] = useState({
    published: [],
    unpublished: [],
  });
  const [error, setError] = useState(null);
  const { dispatch } = useStory(); // Add this line to use the context

  useEffect(() => {
    loadStories();
  }, [activeTab]);

  const loadStories = () => {
    setError(null);
    if (activeTab === "public") {
      loadPublicStories();
    } else {
      loadPrivateStories();
    }
  };

  const loadPublicStories = async () => {
    try {
      const response = await getPublishedStories(isNsfw);
      setPublicStories(response.stories);
    } catch (error) {
      console.error("Failed to load public stories:", error);
      setError("Failed to load public stories. Please try again.");
    }
  };

  const loadPrivateStories = () => {
    try {
      const cachedStories = loadAllStoriesFromLocalStorage(isNsfw);
      setPrivateStories({
        published: cachedStories.filter((story) => story.isPublished),
        unpublished: cachedStories.filter((story) => !story.isPublished),
      });
    } catch (error) {
      console.error("Failed to load private stories from cache:", error);
      setError("Failed to load private stories. Please try again.");
    }
  };

  const handleClearAll = () => {
    if (
      window.confirm("Are you sure you want to clear all unpublished stories?")
    ) {
      clearAllStoriesFromLocalStorage();
      // Reset the state in the context
      dispatch({ type: "RESET_STORY" });
      // Reload private stories to ensure the state is up-to-date
      loadPrivateStories();
    }
  };

  const handleDeleteStory = (storyId) => {
    if (window.confirm("Are you sure you want to delete this story?")) {
      deleteStoryFromLocalStorage(storyId);
      loadPrivateStories();
    }
  };

  const tabs = [
    { id: "public", label: "Discover Stories" },
    { id: "private", label: "My Stories" },
  ];

  const NoStoriesMessage = ({ message }) => (
    <div className="text-gray-500 text-center py-4">{message}</div>
  );

  const helmetData = getStoriesPageHelmet();

  const containerClass = `flex-grow overflow-y-auto h-full flex flex-col ${
    darkMode ? "bg-gray-900" : "bg-gray-100"
  }`;

  const titleClass = `text-2xl font-bold ${
    darkMode ? "text-gray-100" : "text-gray-800"
  }`;

  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" content={helmetData.description} />
        <meta name="keywords" content={helmetData.keywords} />
        <link rel="canonical" href={helmetData.canonicalUrl} />
        <meta name="robots" content={helmetData.robots} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={helmetData.canonicalUrl} />
        <meta property="og:title" content={helmetData.title} />
        <meta property="og:description" content={helmetData.description} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={helmetData.canonicalUrl} />
        <meta property="twitter:title" content={helmetData.title} />
        <meta property="twitter:description" content={helmetData.description} />
      </Helmet>
      <div className={containerClass}>
        <div className="flex justify-between items-center p-4">
          <h1 className={titleClass}>{isNsfw ? "Adult Stories" : "Stories"}</h1>
          <Link
            to={
              isNsfw
                ? "/ai-story-generator/nsfw?new=true"
                : "/ai-story-generator?new=true"
            }
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <FiPlusCircle className="mr-2" />
            Generate New Story
          </Link>
        </div>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-4 mb-4">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={loadStories}
            >
              <span className="text-red-500 hover:text-red-800">Retry</span>
            </button>
          </div>
        )}

        {activeTab === "public" ? (
          <StoryDashboard stories={publicStories} darkMode={darkMode} />
        ) : (
          <div className="p-4">
            <div className="flex justify-between items-center mt-6 mb-2">
              <h2 className="text-xl font-semibold text-purple-600">
                Unpublished
              </h2>
              {privateStories.unpublished.length > 0 && (
                <button
                  onClick={handleClearAll}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  <FiTrash2 className="mr-2" />
                  Clear All
                </button>
              )}
            </div>
            {privateStories.unpublished.length > 0 ? (
              <StoryList
                stories={privateStories.unpublished}
                type="private"
                onDelete={handleDeleteStory}
              />
            ) : (
              <NoStoriesMessage message="No unpublished private stories found." />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default StoriesPage;
