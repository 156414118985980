export const actors = [
  //   {
  //     id: "alloy",
  //     name: "Alloy",
  //     specialty: "Drama, Intense Scenes",
  //     experience: "15 years",
  //     avatar: "👨‍🦰",
  //     voice: "alloy",
  //     bio: "Known for powerful emotional performances and exceptional range.",
  //   },
  {
    id: "ash",
    name: "Ash",
    specialty: "Action, Suspense",
    experience: "12 years",
    avatar: "👨",
    voice: "ash",
    bio: "Masters the art of dynamic and intense delivery.",
  },
  {
    id: "coral",
    name: "Coral",
    specialty: "Comedy, Light Drama",
    experience: "8 years",
    avatar: "👩",
    voice: "coral",
    bio: "Brings warmth and authenticity to every role.",
  },
  {
    id: "sage",
    name: "Sage",
    specialty: "Narration, Documentary",
    experience: "10 years",
    avatar: "👨‍🦱",
    voice: "sage",
    bio: "Clear, authoritative voice with perfect diction.",
  },
  {
    id: "ballad",
    name: "Ballad",
    specialty: "Romance, Drama",
    experience: "7 years",
    avatar: "👩",
    voice: "ballad",
    bio: "Specializes in emotional and romantic scenes.",
  },
  {
    id: "verse",
    name: "Verse",
    specialty: "Animation, Character Work",
    experience: "9 years",
    avatar: "🧑",
    voice: "verse",
    bio: "Versatile voice perfect for animated characters.",
  },
];
