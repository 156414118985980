import React, { createContext, useContext, useReducer } from "react";

const SceneContext = createContext();

const sceneReducer = (state, action) => {
  switch (action.type) {
    case "SET_PREMISE":
      return { ...state, premise: action.payload };
    case "SET_ELEMENTS":
      return { ...state, elements: action.payload };
    case "APPEND_ELEMENTS":
      return { ...state, elements: [...state.elements, ...action.payload] };
    case "INSERT_SCENE":
      return {
        ...state,
        scenes: [
          ...state.scenes.slice(0, action.payload.index),
          action.payload.content,
          ...state.scenes.slice(action.payload.index),
        ],
      };
    case "DELETE_SCENE":
      return {
        ...state,
        scenes: state.scenes.filter((_, idx) => idx !== action.payload),
      };
    case "UPDATE_SCENE":
      return {
        ...state,
        scenes: state.scenes.map((scene, idx) =>
          idx === action.payload.index ? action.payload.content : scene
        ),
      };
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "RESET_SCENE":
      return {
        premise: "",
        genre: "",
        elements: [],
        title: "",
        sceneProgress: 0,
      };
    case "UPDATE_SECTION_SUMMARY":
      return {
        ...state,
        scenes: state.scenes.map((item, idx) =>
          idx === action.payload.index && item.type === "section_break"
            ? { ...item, summary: action.payload.summary }
            : item
        ),
      };
    case "UPDATE_SCENE_PROGRESS":
      return {
        ...state,
        sceneProgress: action.payload.percentage,
      };
    default:
      return state;
  }
};

export const SceneProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sceneReducer, {
    premise: "",
    genre: "",
    elements: [],
    title: "",
    sceneProgress: 0,
  });

  return (
    <SceneContext.Provider value={{ state, dispatch }}>
      {children}
    </SceneContext.Provider>
  );
};

export const useScene = () => {
  const context = useContext(SceneContext);
  if (!context) {
    throw new Error("useScene must be used within a SceneProvider");
  }
  return context;
};
