import React, { useState, useEffect, useRef } from "react";
import useViewportHeight from "./utils/useViewportHeight";
import { Link, useLocation } from "react-router-dom";
import { FaHome } from "react-icons/fa";

export default function MainLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const isDarkMode = location.pathname === "/ai-story-generator/nsfw";
  const isNSFW = location.pathname.includes("nsfw");
  const viewportHeight = useViewportHeight();

  const handleToggleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div
        className={`flex flex-col ${isDarkMode ? "bg-gray-900" : ""}`}
        style={{ height: `${viewportHeight}px` }}
      >
        <div
          className={`w-full ${
            isDarkMode
              ? "bg-gradient-to-r from-gray-800 to-gray-700"
              : "bg-gradient-to-r from-blue-100 to-purple-100"
          } px-4 py-2 flex items-center gap-4`}
        >
          <Link to={isNSFW ? "/nsfw" : "/"}>
            <FaHome
              className={`text-xl ${
                isDarkMode ? "text-gray-100" : "text-gray-800"
              } hover:text-blue-500 transition-colors duration-200`}
            />
          </Link>
          <Link to={isNSFW ? "/nsfw" : "/"}>
            <span
              className={`inline-flex items-center text-sm font-semibold ${
                isDarkMode ? "text-gray-100" : "text-gray-800"
              }`}
            >
              let me
              <span className="mx-1 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-300 ease-in-out">
                WRITE
              </span>
              for you
              {isNSFW && (
                <span className="ml-2 text-[10px] px-1 py-0.5 bg-red-500 text-white rounded-md">
                  NSFW
                </span>
              )}
            </span>
          </Link>
        </div>
        <div className="flex-grow overflow-auto">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { darkMode: isDarkMode })
          )}
        </div>
      </div>
    </>
  );
}
