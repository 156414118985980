import React, { useEffect } from "react";
import SceneMeta from "./SceneMeta";
import SceneElement from "./SceneElement";

const Scene = ({
  elements,
  setActiveElement,
  activeElement,
  darkMode = false,
}) => {
  return (
    <div className={`prose max-w-none ${darkMode ? "dark" : ""}`}>
      {/* <SceneMeta meta={scene.meta} /> */}
      <div
        className={`scene-content p-2 ${darkMode ? "bg-gray-900" : "bg-white"}`}
      >
        {elements?.map((element, elemIndex) => (
          <SceneElement
            key={elemIndex}
            element={element}
            index={elemIndex}
            setActiveElement={setActiveElement}
            isActive={activeElement === elemIndex}
            darkMode={darkMode}
          />
        ))}
      </div>
    </div>
  );
};

export default Scene;
