import React, { useState } from "react";
import { updatePrompt } from "../api/prompts";

function PromptEditor({ name, content, type, onSave }) {
  const [editedContent, setEditedContent] = useState(content);
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await updatePrompt(name, editedContent, type);
      setIsEditing(false);
      onSave();
    } catch (error) {
      console.error("Failed to update prompt:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCancel = () => {
    setEditedContent(content);
    setIsEditing(false);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4">
      <h2 className="text-xl font-semibold mb-2">{name}</h2>
      {isEditing ? (
        <>
          <textarea
            className="w-full h-32 p-2 border rounded mb-2 font-mono whitespace-pre-wrap"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="px-4 py-2 text-sm font-medium text-gray-600 mr-2"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded"
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <>
          <pre className="text-gray-600 mb-2 whitespace-pre-wrap font-mono overflow-x-auto">
            {content}
          </pre>
          <button
            onClick={handleEdit}
            className="px-4 py-2 text-sm font-medium text-blue-500 border border-blue-500 rounded"
          >
            Edit
          </button>
        </>
      )}
    </div>
  );
}

export default PromptEditor;
