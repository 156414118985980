const premiseData = [
  {
    premise: "technology and nature have become inseparable",
    genre: "Science Fiction",
  },
  {
    premise: "humans have developed the ability to communicate with animals",
    genre: "Fantasy",
  },
  {
    premise: "time travel is an everyday occurrence",
    genre: "Science Fiction",
  },
  {
    premise: "dreams can be shared and explored collectively",
    genre: "Science Fiction",
  },
  {
    premise: "emotions can be transferred between people",
    genre: "Science Fiction",
  },
  {
    premise: "memories can be bought and sold",
    genre: "Science Fiction",
  },
  {
    premise: "gravity is no longer constant",
    genre: "Science Fiction",
  },
  {
    premise: "plants have developed consciousness",
    genre: "Fantasy",
  },
  {
    premise: "humans can photosynthesize",
    genre: "Science Fiction",
  },
  {
    premise: "books can read their readers' minds",
    genre: "Fantasy",
  },
  {
    premise: "cities float in the sky",
    genre: "Science Fiction",
  },
  {
    premise: "music has tangible physical properties",
    genre: "Fantasy",
  },
  {
    premise: "shadows have gained sentience",
    genre: "Horror",
  },
  {
    premise: "water has become a rare and precious resource",
    genre: "Post-Apocalyptic",
  },
  {
    premise: "everyone can see one year into their own future",
    genre: "Science Fiction",
  },
];

const nsfwPremiseData = [
  {
    premise: "Erotica about a group of friends go on a camping trip",
    genre: "Adventure",
  },
  {
    premise: "Erotica about a detective investigates a murder",
    genre: "Mystery",
  },
  {
    premise: "Erotica about a group of explorers discover a new island",
    genre: "Adventure",
  },
  {
    premise:
      "Erotica about a boy who discovers he has the power to control time",
    genre: "Science Fiction",
  },
  {
    premise:
      "Erotica about a horny cheerleader who discovers she has the power to control minds",
    genre: "Comedy",
  },
];

const scenePremises = [
  {
    premise: "a group of friends go on a camping trip",
    genre: "Adventure",
  },
  {
    premise: "a detective investigates a murder",
    genre: "Mystery",
  },
  {
    premise: "a group of explorers discover a new island",
    genre: "Adventure",
  },
];

const nsfwScenePremises = [
  {
    premise: "Erotica about a group of friends go on a camping trip",
    genre: "Adventure",
  },
  {
    premise: "Erotica about a detective investigates a murder",
    genre: "Mystery",
  },
  {
    premise: "Erotica about a group of explorers discover a new island",
    genre: "Adventure",
  },
];

export const getRandomPremise = (isNsfw) => {
  const data = isNsfw ? nsfwPremiseData : premiseData;
  const randomIndex = Math.floor(Math.random() * data.length);
  return data[randomIndex];
};

export const getRandomScenePremise = (isNsfw) => {
  const data = isNsfw ? nsfwScenePremises : scenePremises;
  const randomIndex = Math.floor(Math.random() * data.length);
  return data[randomIndex];
};
