import React, { createContext, useContext, useReducer } from "react";

const StoryContext = createContext();

const storyReducer = (state, action) => {
  switch (action.type) {
    case "SET_PREMISE":
      return { ...state, premise: action.payload };
    case "SET_GENRE":
      return { ...state, genre: action.payload };
    case "SET_NSFW":
      return { ...state, isNsfw: action.payload };
    case "SET_PARAGRAPHS":
      return { ...state, paragraphs: action.payload };
    case "ADD_PARAGRAPHS":
      return { ...state, paragraphs: [...state.paragraphs, ...action.payload] };
    case "INSERT_PARAGRAPH":
      return {
        ...state,
        paragraphs: [
          ...state.paragraphs.slice(0, action.payload.index),
          action.payload.content,
          ...state.paragraphs.slice(action.payload.index),
        ],
      };
    case "DELETE_PARAGRAPH":
      return {
        ...state,
        paragraphs: state.paragraphs.filter((_, idx) => idx !== action.payload),
      };
    case "UPDATE_PARAGRAPH":
      return {
        ...state,
        paragraphs: state.paragraphs.map((paragraph, idx) =>
          idx === action.payload.index ? action.payload.content : paragraph
        ),
      };
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "RESET_STORY":
      return {
        premise: "",
        genre: "",
        paragraphs: [],
        title: "",
        generationMode: "open_ended",
      };
    case "ADD_SECTION_BREAK":
      return {
        ...state,
        paragraphs: [
          ...state.paragraphs.slice(0, action.payload.index),
          { type: "section_break", summary: action.payload.summary },
          ...state.paragraphs.slice(action.payload.index),
        ],
      };
    case "REMOVE_SECTION_BREAK":
      return {
        ...state,
        paragraphs: state.paragraphs.filter(
          (_, idx) => idx !== action.payload.index
        ),
      };
    case "UPDATE_SECTION_SUMMARY":
      return {
        ...state,
        paragraphs: state.paragraphs.map((item, idx) =>
          idx === action.payload.index && item.type === "section_break"
            ? { ...item, summary: action.payload.summary }
            : item
        ),
      };
    case "SET_COLLAPSED_SECTIONS":
      return { ...state, collapsedSections: action.payload };
    case "TOGGLE_SECTION_COLLAPSE":
      return {
        ...state,
        collapsedSections: state.collapsedSections.includes(action.payload)
          ? state.collapsedSections.filter((index) => index !== action.payload)
          : [...state.collapsedSections, action.payload],
      };
    case "SET_ALL_SECTIONS_COLLAPSED":
      return { ...state, areAllSectionsCollapsed: action.payload };
    case "TOGGLE_ALL_SECTIONS":
      const allSectionBreakIndices = state.paragraphs
        .map((item, index) => (item.type === "section_break" ? index : -1))
        .filter((index) => index !== -1);
      return {
        ...state,
        collapsedSections: state.areAllSectionsCollapsed
          ? []
          : allSectionBreakIndices,
        areAllSectionsCollapsed: !state.areAllSectionsCollapsed,
      };
    case "UPDATE_SECTION_BREAK_SUMMARY":
      return {
        ...state,
        paragraphs: state.paragraphs.map((item, idx) =>
          idx === action.payload.index && item.type === "section_break"
            ? { ...item, summary: action.payload.summary }
            : item
        ),
      };
    case "UPDATE_STORY_PROGRESS":
      return {
        ...state,
        storyProgress: action.payload.percentage,
      };
    case "SET_GENERATION_MODE":
      return { ...state, generationMode: action.payload };
    case "SET_OVERALL_SUMMARY":
      return { ...state, overall_summary: action.payload };
    default:
      return state;
  }
};

export const StoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storyReducer, {
    premise: "",
    genre: "",
    paragraphs: [],
    title: "",
    collapsedSections: [],
    areAllSectionsCollapsed: false,
    storyProgress: 0,
    generationMode: "open_ended",
    overall_summary: "",
    isNsfw: false,
  });

  return (
    <StoryContext.Provider value={{ state, dispatch }}>
      {children}
    </StoryContext.Provider>
  );
};

export const useStory = () => {
  const context = useContext(StoryContext);
  if (!context) {
    throw new Error("useStory must be used within a StoryProvider");
  }
  return context;
};
