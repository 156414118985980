import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Tabs from "../components/Tabs";
import SceneDashboard from "../components/scenes/SceneDashboard";
import SceneList from "../components/scenes/SceneList";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";
import {
  loadAllScenesFromLocalStorage,
  clearAllScenesFromLocalStorage,
  deleteSceneFromLocalStorage,
} from "../utils/storageUtils";
import { useScene } from "../context/SceneContext"; // Add this import
import { getScenesPageHelmet } from "../utils/helmetUtils";

const ScenesPage = ({ darkMode = false, isNsfw = false }) => {
  const [activeTab, setActiveTab] = useState("private");
  const [publicScenes, setPublicScenes] = useState([]);
  const [privateScenes, setPrivateScenes] = useState({
    published: [],
    unpublished: [],
  });
  const [error, setError] = useState(null);
  const { dispatch } = useScene(); // Add this line to use the context

  useEffect(() => {
    loadScenes();
  }, [activeTab]);

  const loadScenes = () => {
    setError(null);
    if (activeTab === "public") {
      // loadPublicScenes();
    } else {
      loadPrivateScenes();
    }
  };

  // const loadPublicScenes = async () => {
  //   try {
  //     const response = await getPublishedScenes(isNsfw);
  //     setPublicScenes(response.stories);
  //   } catch (error) {
  //     console.error("Failed to load public stories:", error);
  //     setError("Failed to load public stories. Please try again.");
  //   }
  // };

  const loadPrivateScenes = () => {
    try {
      const cachedScenes = loadAllScenesFromLocalStorage(isNsfw);
      setPrivateScenes({
        published: cachedScenes.filter((scene) => scene.isPublished),
        unpublished: cachedScenes.filter((scene) => !scene.isPublished),
      });
    } catch (error) {
      console.error("Failed to load private stories from cache:", error);
      setError("Failed to load private stories. Please try again.");
    }
  };

  const handleClearAll = () => {
    if (
      window.confirm("Are you sure you want to clear all unpublished stories?")
    ) {
      clearAllScenesFromLocalStorage();
      // Reset the state in the context
      dispatch({ type: "RESET_STORY" });
      // Reload private stories to ensure the state is up-to-date
      loadPrivateScenes();
    }
  };

  const handleDeleteScene = (sceneId) => {
    if (window.confirm("Are you sure you want to delete this scene?")) {
      deleteSceneFromLocalStorage(sceneId);
      loadPrivateScenes();
    }
  };

  const tabs = [
    { id: "public", label: "Discover Scenes" },
    { id: "private", label: "My Scenes" },
  ];

  const NoScenesMessage = ({ message }) => (
    <div className="text-gray-500 text-center py-4">{message}</div>
  );

  const helmetData = getScenesPageHelmet(isNsfw);

  const containerClass = `flex-grow overflow-y-auto h-full flex flex-col ${
    darkMode ? "bg-gray-900" : "bg-gray-100"
  }`;

  const titleClass = `text-2xl font-bold ${
    darkMode ? "text-gray-100" : "text-gray-800"
  }`;

  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" content={helmetData.description} />
        <meta name="keywords" content={helmetData.keywords} />
        <link rel="canonical" href={helmetData.canonicalUrl} />
        <meta name="robots" content={helmetData.robots} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={helmetData.canonicalUrl} />
        <meta property="og:title" content={helmetData.title} />
        <meta property="og:description" content={helmetData.description} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={helmetData.canonicalUrl} />
        <meta property="twitter:title" content={helmetData.title} />
        <meta property="twitter:description" content={helmetData.description} />
      </Helmet>
      <div className={containerClass}>
        <div className="flex justify-between items-center p-4">
          <h1 className={titleClass}>{isNsfw ? "Adult Scenes" : "Scenes"}</h1>
          <Link
            to={
              isNsfw
                ? "/ai-scene-generator/nsfw?new=true"
                : "/ai-scene-generator?new=true"
            }
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <FiPlusCircle className="mr-2" />
            Generate New Scene
          </Link>
        </div>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-4 mb-4">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={loadScenes}
            >
              <span className="text-red-500 hover:text-red-800">Retry</span>
            </button>
          </div>
        )}

        {activeTab === "public" ? (
          <SceneDashboard scenes={publicScenes} darkMode={darkMode} />
        ) : (
          <div className="p-4">
            <div className="flex justify-between items-center mt-6 mb-2">
              <h2 className="text-xl font-semibold text-purple-600">
                Unpublished
              </h2>
              {privateScenes.unpublished.length > 0 && (
                <button
                  onClick={handleClearAll}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  <FiTrash2 className="mr-2" />
                  Clear All
                </button>
              )}
            </div>
            {privateScenes.unpublished.length > 0 ? (
              <SceneList
                scenes={privateScenes.unpublished}
                type="private"
                onDelete={handleDeleteScene}
              />
            ) : (
              <NoScenesMessage message="No unpublished private stories found." />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ScenesPage;
