import React from "react";
import { FiLoader } from "react-icons/fi";

const StoryTitle = ({
  title,
  isGeneratingTitle,
  onGenerateTitle,
  isCompact,
  darkMode = false,
}) => {
  return (
    <div
      className={`flex items-center justify-between w-full ${
        isCompact ? "mb-0" : "mb-2"
      }`}
    >
      <h1
        className={`font-bold truncate flex-grow transition-all duration-300 text-lg sm:text-xl ${
          darkMode ? "text-gray-100" : "text-gray-800"
        }`}
      >
        {isGeneratingTitle ? (
          <span className="flex items-center">
            <FiLoader
              className={`animate-spin mr-2 ${
                darkMode ? "text-amber-400" : "text-amber-500"
              }`}
              size={isCompact ? 16 : 20}
            />
            <span>Generating title...</span>
          </span>
        ) : (
          title
        )}
      </h1>
    </div>
  );
};

export default StoryTitle;
