import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ContentGenerator from "../components/ContentGenerator";
import { useStory } from "../context/StoryContext";
import { getContextForMode } from "../utils/contextUtils";

const StoryGeneratorCompact = ({ darkMode = false, isNsfw = false }) => {
  const location = useLocation();
  const history = useHistory();
  const { state, dispatch } = useStory();
  const [mode, setMode] = useState("new_paragraphs");
  const [paragraphContext, setParagraphContext] = useState(null);

  useEffect(() => {
    // Get mode and context from navigation state
    if (location.state) {
      const { mode: navMode, paragraphContext: navContext } = location.state;
      if (navMode) setMode(navMode);
      if (navContext) {
        setParagraphContext(navContext);
      } else {
        setParagraphContext(null);
      }
    }
  }, [location]);

  const handleContentFinalize = (content) => {
    let path = isNsfw ? "/ai-story-generator/nsfw" : "/ai-story-generator";
    history.push(path, {
      fromCompactView: true,
      generatedContent: content,
      mode,
      paragraphContext,
    });
  };

  const getContext = useCallback(() => {
    if (mode === "insert" && paragraphContext) {
      return getContextForMode(mode, state, paragraphContext.index);
    }
    return getContextForMode(mode, state, 0);
  }, [mode, state, paragraphContext]);

  const getTitle = () => {
    switch (mode) {
      case "insert":
        return "Insert new paragraphs";
      case "rewrite":
        return "Rewrite paragraph";
      default:
        return state.generationMode === "percentage_based"
          ? "Generate New Paragraphs"
          : "Generate New Scene";
    }
  };

  const onExpansion = () => {
    history.push("/ai-story-generator", {
      fromSlimVersion: true,
    });
  };

  return (
    <div
      className={`flex flex-col ${
        darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-50"
      }`}
    >
      <div className="flex-grow container mx-auto px-4 py-2 max-w-8xl">
        <ContentGenerator
          onFinalize={handleContentFinalize}
          mode={mode}
          context={getContext()}
          title={getTitle()}
          onSaveInstruction={() => {}}
          generationMode={state.generationMode}
          isNsfw={isNsfw || (paragraphContext?.isNsfw ?? false)}
          onExpanded={onExpansion}
        />
      </div>
    </div>
  );
};

export default StoryGeneratorCompact;
