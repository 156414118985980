import React, { useState } from "react";
import SceneWordByWordRenderer from "../components/scene/SceneWordByWordRenderer";
import { FaPlay, FaSync } from "react-icons/fa";

const SceneWordByWordDemo = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [content, setContent] = useState([]);

  const sampleScene = [
    {
      type: "action",
      description:
        "The ancient library stands silent, dust motes dancing in the filtered sunlight.",
    },
    {
      type: "action",
      description:
        "Sarah's fingers tremble as she reaches for the leather-bound tome.",
    },
    {
      type: "dialogue",
      character: "Tom",
      parenthetical: "whispering nervously",
      line: "We shouldn't be here. This place gives me the creeps.",
    },
    {
      type: "dialogue",
      character: "Sarah",
      parenthetical: "determined",
      line: "We have to find that spell, Tom. It's our only chance.",
    },
    {
      type: "action",
      description: "A sudden gust of wind extinguishes the nearby candles.",
    },
    {
      type: "internal_monologue",
      character: "Sarah",
      description:
        "I can feel the darkness closing in. Maybe Tom is right... but we've come too far to turn back now.",
    },
    {
      type: "dialogue",
      character: "Tom",
      parenthetical: "panicking",
      line: "Did you hear that? I think something's moving in the shadows!",
    },
    {
      type: "transition",
      description: "FADE TO BLACK",
    },
  ];

  const generateContent = () => {
    setContent([]);
    setTimeout(() => {
      setIsGenerating(true);
      setContent(sampleScene);
    }, 100);
  };

  const handleComplete = () => {
    setIsGenerating(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 p-8">
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-2">
            AI Scene Generator
          </h1>
          <p className="text-gray-400">Experience stories coming to life</p>
        </div>

        {/* Control Panel */}
        <div className="flex justify-center mb-8">
          <button
            onClick={generateContent}
            disabled={isGenerating}
            className="group relative flex items-center gap-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-3 rounded-lg hover:from-purple-700 hover:to-pink-700 disabled:from-gray-600 disabled:to-gray-600 transition-all duration-300 shadow-lg hover:shadow-purple-500/25"
          >
            {isGenerating ? (
              <>
                <FaSync className="animate-spin" />
                <span>Generating...</span>
              </>
            ) : (
              <>
                <FaPlay />
                <span>Play Scene</span>
              </>
            )}
            <div className="absolute inset-0 rounded-lg bg-white opacity-0 group-hover:opacity-10 transition-opacity duration-300"></div>
          </button>
        </div>

        {/* Scene Display */}
        <div className="relative">
          {/* Decorative Elements */}
          <div className="absolute inset-0 bg-gradient-to-b from-purple-500/5 to-pink-500/5 rounded-xl"></div>
          <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500/30 to-pink-500/30 rounded-xl blur opacity-20"></div>

          {/* Main Content */}
          <div className="relative bg-gray-900/90 backdrop-blur-sm rounded-xl border border-gray-700 shadow-2xl">
            <div className="p-6">
              {content.length === 0 ? (
                <div className="text-center py-40 text-gray-500">
                  <p className="text-lg mb-2">No scene loaded</p>
                  <p className="text-sm">
                    Click "Play Scene" to begin the story
                  </p>
                </div>
              ) : (
                <div className="h-[500px] overflow-hidden">
                  <SceneWordByWordRenderer
                    elements={content}
                    typingSpeed={200}
                    dialogueTypingSpeed={250}
                    dialoguePause={500}
                    onComplete={handleComplete}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Bottom Gradient Fade */}
        <div className="relative h-8 -mt-8 bg-gradient-to-t from-gray-900 to-transparent pointer-events-none"></div>
      </div>
    </div>
  );
};

export default SceneWordByWordDemo;
