import { apiCall } from "../utils/api_utils";
import yaml from "js-yaml";

const API_ENDPOINTS = {
  FETCH_PROMPTS: "api/v1/prompts",
  UPDATE_PROMPT: "api/v1/prompts/update",
};

export const fetchPrompts = async () => {
  try {
    const response = await apiCall(API_ENDPOINTS.FETCH_PROMPTS, "GET");
    return response;
  } catch (error) {
    console.error("Error fetching prompts:", error);
    throw error;
  }
};

export const updatePrompt = async (name, content, type) => {
  try {
    const response = await apiCall(API_ENDPOINTS.UPDATE_PROMPT, "POST", {
      name,
      content,
      type,
    });
    return response;
  } catch (error) {
    console.error("Error updating prompt:", error);
    throw error;
  }
};
