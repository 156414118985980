import React, { useState, useEffect } from "react";
import { diffSentences } from "../utils/paragraphUtils";

const EditingParagraph = ({ content, originalContent, onEditComplete }) => {
  const [editedContent, setEditedContent] = useState(content);
  const [isProcessing, setIsProcessing] = useState(true);
  const [currentSpanIndex, setCurrentSpanIndex] = useState(0);
  const [spanPositions, setSpanPositions] = useState([]);
  const [isHighlighting, setIsHighlighting] = useState(true);

  useEffect(() => {
    processContent();
  }, [content, originalContent]);

  useEffect(() => {
    if (spanPositions.length > 0) {
      processNextSpan();
    }
  }, [
    content,
    currentSpanIndex,
    onEditComplete,
    spanPositions,
    isHighlighting,
  ]);

  const removeSpanTags = (text) => {
    return text.replace(/<\/?span[^>]*>/g, "");
  };

  const removeHighlights = (text) => {
    return text.replace(/<span class="bg-yellow-200">(.*?)<\/span>/g, "$1");
  };

  const processContent = () => {
    const positions = [];
    console.log(content);
    const regex = /<span[^>]*>(.*?)<\/span>/g;
    let match;
    while ((match = regex.exec(content)) !== null) {
      console.log(match);
      positions.push({
        start: match.index,
        end: match.index + match[0].length,
        text: match[1],
      });
    }
    setSpanPositions(positions);
    setCurrentSpanIndex(0);
    setIsHighlighting(true);
  };

  const processNextSpan = () => {
    if (currentSpanIndex < spanPositions.length) {
      if (isHighlighting) {
        highlightSpan();
      } else {
        modifySpan();
      }
    } else {
      setIsProcessing(false);
      const finalContent = removeHighlights(editedContent);
      onEditComplete(finalContent); // Send the updated content without highlights
    }
  };

  const highlightSpan = () => {
    const newContent = highlightSpanAtIndex(editedContent, currentSpanIndex);
    setEditedContent(newContent);
    setTimeout(() => {
      setIsHighlighting(false);
    }, 1000); // Delay before modifying the highlighted span
  };

  const modifySpan = () => {
    const newContent = modifySpanAtIndex(editedContent, currentSpanIndex);
    setEditedContent(newContent);
    setTimeout(() => {
      setCurrentSpanIndex(currentSpanIndex + 1);
      setIsHighlighting(true);
    }, 1000); // Delay before moving to the next span
  };

  const highlightSpanAtIndex = (text, index) => {
    const { start, end, text: spanText } = spanPositions[index];
    return (
      text.slice(0, start) +
      `<span class="bg-yellow-200">${spanText}</span>` +
      text.slice(end)
    );
  };

  const modifySpanAtIndex = (text, index) => {
    const { start, end, text: spanText } = spanPositions[index];
    const trimmedSpanText = spanText.trimStart();
    const modifiedSpanText = `m-${trimmedSpanText}`;
    return (
      text.slice(0, start) +
      `<span class="bg-yellow-200">${modifiedSpanText}</span>` +
      text.slice(end)
    );
  };

  if (isProcessing) {
    return (
      <div className="relative border-2 border-indigo-500 bg-amber-50 p-4 rounded-lg">
        <p
          className="text-base sm:text-lg leading-relaxed text-gray-800 p-2 font-serif"
          dangerouslySetInnerHTML={{ __html: editedContent }}
        />
      </div>
    );
  }

  return (
    <div className="border-2 border-indigo-500 bg-amber-50 p-4 rounded-lg">
      <p
        className="text-base sm:text-lg leading-relaxed text-gray-800 p-2 font-serif"
        dangerouslySetInnerHTML={{ __html: removeHighlights(editedContent) }}
      />
    </div>
  );
};

export default EditingParagraph;
