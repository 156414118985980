import { apiCall } from "../utils/api_utils";

const API_ENDPOINTS = {
  FETCH_MODELS: "api/v1/models",
  CURR_MODEL: "api/v1/model/current",
  UPDATE_CURR_MODEL: "api/v1/model/update",
};

export const fetchModels = async () => {
  try {
    const response = await apiCall(API_ENDPOINTS.FETCH_MODELS, "GET");
    return response.models;
  } catch (error) {
    console.error("Error fetching models:", error);
    return [];
  }
};

export const getCurrentModel = async () => {
  try {
    const response = await apiCall(API_ENDPOINTS.CURR_MODEL, "GET");
    return response.currentModel;
  } catch (error) {
    console.error("Error fetching current model:", error);
    return "";
  }
};

export const updateCurrentModel = async (model) => {
  try {
    await apiCall(API_ENDPOINTS.UPDATE_CURR_MODEL, "POST", { model });
  } catch (error) {
    console.error("Error updating current model:", error);
  }
};
