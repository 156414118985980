import React from "react";
import { FaParagraph } from "react-icons/fa";

const VerticalSlider = ({ value, onChange, min = 1, max = 5 }) => {
  const handleChange = (e) => {
    onChange(parseInt(e.target.value));
  };

  return (
    <div className="flex items-center bg-yellow-100 rounded-md p-2">
      <FaParagraph className="text-yellow-600 mr-2" />
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className="w-24 h-8 appearance-none bg-yellow-200 rounded-lg cursor-pointer
        [&::-webkit-slider-thumb]:appearance-none
        [&::-webkit-slider-thumb]:w-6
        [&::-webkit-slider-thumb]:h-6
        [&::-webkit-slider-thumb]:rounded-full
        [&::-webkit-slider-thumb]:bg-yellow-500
        [&::-webkit-slider-thumb]:shadow-md
        [&::-webkit-slider-thumb]:cursor-pointer
        [&::-webkit-slider-thumb]:border-2
        [&::-webkit-slider-thumb]:border-yellow-400
        [&::-webkit-slider-thumb]:hover:bg-yellow-600
        
        [&::-moz-range-thumb]:w-6
        [&::-moz-range-thumb]:h-6
        [&::-moz-range-thumb]:rounded-full
        [&::-moz-range-thumb]:bg-yellow-500
        [&::-moz-range-thumb]:shadow-md
        [&::-moz-range-thumb]:cursor-pointer
        [&::-moz-range-thumb]:border-2
        [&::-moz-range-thumb]:border-yellow-400
        [&::-moz-range-thumb]:hover:bg-yellow-600"
      />
      <span className="text-yellow-700 ml-2 min-w-[1.5rem] text-center md:hidden">
        {value}
      </span>
    </div>
  );
};

export default VerticalSlider;
