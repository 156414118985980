import React from "react";

const RewrittenParagraph = ({ sentences, onAccept, onReject }) => {
  const compareAndFormat = (original, rewritten) => {
    const originalWords = original.split(/\s+/);
    const rewrittenWords = rewritten.split(/\s+/);
    const result = [];

    // Function to compute the LCS matrix
    const computeLCSMatrix = (a, b) => {
      const m = a.length;
      const n = b.length;
      const matrix = Array(m + 1)
        .fill(null)
        .map(() => Array(n + 1).fill(0));

      for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
          if (a[i - 1] === b[j - 1]) {
            matrix[i][j] = matrix[i - 1][j - 1] + 1;
          } else {
            matrix[i][j] = Math.max(matrix[i - 1][j], matrix[i][j - 1]);
          }
        }
      }
      return matrix;
    };

    // Function to backtrack the LCS matrix and find matching indices
    const backtrackLCS = (matrix, a, b) => {
      const matches = [];
      let i = a.length;
      let j = b.length;

      while (i > 0 && j > 0) {
        if (a[i - 1] === b[j - 1]) {
          matches.unshift({ indexA: i - 1, indexB: j - 1, word: a[i - 1] });
          i--;
          j--;
        } else if (matrix[i - 1][j] > matrix[i][j - 1]) {
          i--;
        } else {
          j--;
        }
      }
      return matches;
    };

    const lcsMatrix = computeLCSMatrix(originalWords, rewrittenWords);
    const matches = backtrackLCS(lcsMatrix, originalWords, rewrittenWords);

    let origIndex = 0;
    let rewIndex = 0;
    let editBufferOriginal = [];
    let editBufferRewritten = [];

    matches.forEach((match) => {
      // Collect any differences before the current match
      while (origIndex < match.indexA || rewIndex < match.indexB) {
        if (origIndex < match.indexA) {
          editBufferOriginal.push(originalWords[origIndex]);
          origIndex++;
        }
        if (rewIndex < match.indexB) {
          editBufferRewritten.push(rewrittenWords[rewIndex]);
          rewIndex++;
        }
      }

      // If there are edits buffered, format them
      if (editBufferOriginal.length > 0 || editBufferRewritten.length > 0) {
        if (editBufferOriginal.length > 0) {
          result.push(
            `<span class="line-through text-red-500 bg-red-100">${editBufferOriginal.join(
              " "
            )}</span>`
          );
        }
        if (editBufferRewritten.length > 0) {
          result.push(
            `<span class="font-semibold text-green-700 bg-green-100">${editBufferRewritten.join(
              " "
            )}</span>`
          );
        }
        // Clear the buffers
        editBufferOriginal = [];
        editBufferRewritten = [];
      }

      // Add the matching word
      result.push(originalWords[origIndex] || rewrittenWords[rewIndex]);
      origIndex++;
      rewIndex++;
    });

    // Handle any remaining edits after the last match
    while (
      origIndex < originalWords.length ||
      rewIndex < rewrittenWords.length
    ) {
      if (origIndex < originalWords.length) {
        editBufferOriginal.push(originalWords[origIndex]);
        origIndex++;
      }
      if (rewIndex < rewrittenWords.length) {
        editBufferRewritten.push(rewrittenWords[rewIndex]);
        rewIndex++;
      }
    }

    if (editBufferOriginal.length > 0 || editBufferRewritten.length > 0) {
      if (editBufferOriginal.length > 0) {
        result.push(
          `<span class="line-through text-red-500 bg-red-100">${editBufferOriginal.join(
            " "
          )}</span>`
        );
      }
      if (editBufferRewritten.length > 0) {
        result.push(
          `<span class="font-semibold text-green-700 bg-green-100">${editBufferRewritten.join(
            " "
          )}</span>`
        );
      }
    }

    return result.join(" ");
  };

  const renderSentences = () => {
    if (!sentences) return null;
    return sentences.map((sentence, index) => {
      switch (sentence.action) {
        case "edit":
          const formattedSentence = compareAndFormat(
            sentence.original_sentence,
            sentence.rewritten_sentence
          );
          return (
            <span
              key={index}
              dangerouslySetInnerHTML={{ __html: formattedSentence }}
              className="text-base"
            />
          );
        case "remove":
          return (
            <span key={index} className="line-through text-red-500 bg-red-100">
              {sentence.original_sentence}{" "}
            </span>
          );
        case "add":
          return (
            //add a space if the sentence is not the first one
            <span
              key={index}
              className="text-base font-semibold text-green-700 bg-green-100"
            >
              {index > 0 && " "}
              {sentence.rewritten_sentence}{" "}
            </span>
          );
        default:
          return (
            <span key={index} className="text-base">
              {index > 0 && " "}
              {sentence.original_sentence}{" "}
            </span>
          );
      }
    });
  };

  return (
    <div className="border-2 border-indigo-500 bg-amber-50 p-4 rounded-lg">
      <p className="text-lg leading-relaxed text-gray-800 mb-4">
        {renderSentences()}
      </p>
      {/* <div className="flex justify-end space-x-4">
        <button
          onClick={onAccept}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Accept
        </button>
        <button
          onClick={onReject}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Reject
        </button>
      </div> */}
    </div>
  );
};

export default RewrittenParagraph;
