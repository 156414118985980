import React from "react";
import { Link } from "react-router-dom";

const SceneDashboard = ({ scenes = [], darkMode = false }) => {
  const containerClass = `grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 ${
    darkMode ? "text-gray-100" : "text-gray-800"
  }`;

  const cardClass = `rounded-lg shadow-lg overflow-hidden ${
    darkMode ? "bg-gray-800" : "bg-white"
  }`;

  if (!scenes.length) {
    return (
      <div className="text-center py-8">
        <p
          className={`text-lg ${darkMode ? "text-gray-300" : "text-gray-600"}`}
        >
          No published scenes available.
        </p>
      </div>
    );
  }

  return (
    <div className={containerClass}>
      {scenes.map((story) => (
        <div key={story.id} className={cardClass}>
          <div className="p-4">
            <h3 className="text-xl font-semibold mb-2">{story.title}</h3>
            <p
              className={`mb-4 ${darkMode ? "text-gray-300" : "text-gray-600"}`}
            >
              {story.premise.substring(0, 150)}...
            </p>
            <div className="flex justify-between items-center">
              <span
                className={`text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-500"
                }`}
              >
                {new Date(story.created_at).toLocaleDateString()}
              </span>
              <Link
                to={`/scene/${story.id}`}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SceneDashboard;
