import React, { useState } from "react";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  formContent,
  onEditForm,
  darkMode = false,
}) => {
  const [authorName, setAuthorName] = useState("Anonymous");
  const [isEditing, setIsEditing] = useState(false);
  const [editedFormData, setEditedFormData] = useState(null);

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(authorName);
  };

  const handleEdit = () => {
    setIsEditing(true);
    // Initialize edited form data if not already set
    if (!editedFormData && formContent) {
      setEditedFormData(formContent);
    }
  };

  const handleSaveEdit = () => {
    setIsEditing(false);
    if (onEditForm) {
      onEditForm(editedFormData);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className={`fixed inset-0 ${
          darkMode ? "bg-black" : "bg-gray-800"
        } opacity-50`}
        onClick={onClose}
      ></div>
      <div
        className={`${
          darkMode ? "bg-gray-800" : "bg-white"
        } rounded-lg p-6 max-w-sm w-full mx-4 z-50`}
      >
        <h3
          className={`text-lg font-semibold mb-2 ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          {title}
        </h3>

        {formContent ? (
          <div className="mb-4">
            {isEditing ? (
              <div className="space-y-4">
                <input
                  type="text"
                  value={editedFormData.title || ""}
                  onChange={(e) =>
                    setEditedFormData({
                      ...editedFormData,
                      title: e.target.value,
                    })
                  }
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${
                      darkMode
                        ? "bg-gray-700 border-gray-600 text-gray-100"
                        : "bg-white border-gray-300 text-gray-900"
                    }`}
                />
                <input
                  type="text"
                  value={editedFormData.author || ""}
                  onChange={(e) =>
                    setEditedFormData({
                      ...editedFormData,
                      author: e.target.value,
                    })
                  }
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${
                      darkMode
                        ? "bg-gray-700 border-gray-600 text-gray-100"
                        : "bg-white border-gray-300 text-gray-900"
                    }`}
                />
                <button
                  onClick={handleSaveEdit}
                  className={`px-4 py-2 text-sm text-white rounded 
                    ${
                      darkMode
                        ? "bg-green-600 hover:bg-green-700"
                        : "bg-green-500 hover:bg-green-600"
                    }`}
                >
                  Save Changes
                </button>
              </div>
            ) : (
              <div className="relative">
                <div
                  dangerouslySetInnerHTML={{ __html: formContent.html }}
                  className={darkMode ? "text-gray-100" : "text-gray-900"}
                />
                <button
                  onClick={handleEdit}
                  className={`absolute top-0 right-0 p-1 
                    ${
                      darkMode
                        ? "text-blue-400 hover:text-blue-300"
                        : "text-blue-500 hover:text-blue-600"
                    }`}
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        ) : (
          <p className={`mb-4 ${darkMode ? "text-gray-300" : "text-gray-600"}`}>
            {message}
          </p>
        )}

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className={`px-4 py-2 text-sm rounded border
              ${
                darkMode
                  ? "text-gray-300 hover:bg-gray-700 border-gray-600"
                  : "text-gray-600 hover:bg-gray-100 border-gray-300"
              }`}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            disabled={isEditing}
            className={`px-4 py-2 text-sm text-white rounded 
              ${
                isEditing
                  ? darkMode
                    ? "bg-blue-600/50 cursor-not-allowed"
                    : "bg-blue-300 cursor-not-allowed"
                  : darkMode
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
