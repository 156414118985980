import React, { useState } from "react";

const DialogueFormModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    scene: "",
    mood: "",
    context: "",
    dialogue: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({ scene: "", mood: "", context: "", dialogue: "" });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl text-white font-bold mb-4">Add New Dialogue</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-300 mb-1">Scene</label>
              <input
                type="text"
                value={formData.scene}
                onChange={(e) =>
                  setFormData({ ...formData, scene: e.target.value })
                }
                className="w-full bg-gray-700 text-white rounded p-2"
                required
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-1">Mood</label>
              <input
                type="text"
                value={formData.mood}
                onChange={(e) =>
                  setFormData({ ...formData, mood: e.target.value })
                }
                className="w-full bg-gray-700 text-white rounded p-2"
                required
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-1">Context</label>
              <textarea
                value={formData.context}
                onChange={(e) =>
                  setFormData({ ...formData, context: e.target.value })
                }
                className="w-full bg-gray-700 text-white rounded p-2"
                required
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-1">Dialogue Text</label>
              <textarea
                value={formData.dialogue}
                onChange={(e) =>
                  setFormData({ ...formData, dialogue: e.target.value })
                }
                className="w-full bg-gray-700 text-white rounded p-2"
                required
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-500"
            >
              Add Dialogue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DialogueFormModal;
