import React, { useState, useEffect } from "react";
import { FaTimes, FaUndo } from "react-icons/fa";

const ClearableTextarea = ({
  value,
  onChange,
  onFocus,
  onBlur,
  onClear,
  placeholder,
  rows = 3,
  className = "",
  isNsfw = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [previousValue, setPreviousValue] = useState("");
  const [showUndo, setShowUndo] = useState(false);

  useEffect(() => {
    if (value !== "" && value !== previousValue) {
      setPreviousValue(value);
    }
  }, [value]);

  const handleClear = () => {
    setPreviousValue(value);
    onChange("");
    setShowUndo(true);
    onClear && onClear();
  };

  const handleUndo = () => {
    onChange(previousValue);
    setShowUndo(false);
  };

  const handleChange = (e) => {
    onChange(e.target.value);
    if (showUndo) {
      setShowUndo(false);
    }
  };

  return (
    <div className="relative">
      <textarea
        value={value}
        onChange={handleChange}
        onFocus={() => {
          setIsFocused(true);
          onFocus && onFocus();
        }}
        onBlur={() => {
          setIsFocused(false);
          onBlur && onBlur();
        }}
        placeholder={isNsfw ? `${placeholder} (NSFW Mode)` : placeholder}
        className={`w-full pl-6 pr-12 sm:pr-36 border ${
          isNsfw ? "border-red-300 text-black" : "border-gray-300 text-black"
        } rounded-md focus:ring-2 ${
          isNsfw ? "focus:ring-red-500" : "focus:ring-blue-500"
        } focus:border-transparent transition-all duration-200 resize-none ${className}`}
        style={{ fontSize: "16px" }}
        rows={rows}
        {...props}
      />
      {value && (isFocused || value) && (
        <button
          onClick={handleClear}
          className={`absolute top-2 left-1 p-1 ${
            isNsfw
              ? "text-red-400 hover:text-red-600"
              : "text-gray-400 hover:text-gray-600"
          } transition-colors duration-200 bg-white rounded-full`}
          aria-label="Clear text"
        >
          <FaTimes size={12} />
        </button>
      )}
      {showUndo && (
        <button
          onClick={handleUndo}
          className={`absolute top-2 left-1 p-1 ${
            isNsfw
              ? "text-red-500 hover:text-red-600"
              : "text-blue-500 hover:text-blue-600"
          } transition-colors duration-200 bg-white rounded-full`}
          aria-label="Undo clear"
        >
          <FaUndo size={12} />
        </button>
      )}
    </div>
  );
};

export default ClearableTextarea;
