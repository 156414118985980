import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import {
  BsExclamationTriangle,
  BsShieldLock,
  BsEyeSlash,
  BsStars,
  BsLightningFill,
} from "react-icons/bs";
import { getNSFWLandingPageHelmet } from "../utils/helmetUtils";

const NSFWLandingPage = () => {
  const history = useHistory();
  const [ageVerified, setAgeVerified] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(0);

  const prompts = [
    "Creating passionate scenes...",
    "Developing chemistry...",
    "Adding sensual details...",
    "Ready to write.",
  ];

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentPrompt((prev) => (prev + 1) % prompts.length);
    }, 2000);

    return () => clearInterval(textInterval);
  }, []);

  const handleStartWriting = () => {
    if (ageVerified) {
      history.push("/ai-story-generator/nsfw");
    }
  };

  const handleViewStories = () => {
    history.push("/stories/nsfw");
  };

  const handleAgeVerification = () => {
    setAgeVerified(true);
  };

  return (
    <>
      <Helmet {...getNSFWLandingPageHelmet()} />

      <div className="min-h-screen bg-gray-950 text-pink-400">
        {!ageVerified ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full mx-4">
              <BsExclamationTriangle className="w-12 h-12 mx-auto mb-6 text-pink-500" />
              <h2 className="text-2xl font-bold text-center mb-4">
                Age Verification Required
              </h2>
              <p className="text-gray-300 mb-6 text-center">
                This section contains adult content. You must be 18 or older to
                continue.
              </p>
              <button
                onClick={handleAgeVerification}
                className="w-full bg-pink-950 text-pink-400 font-mono py-3 px-6 rounded-lg transition-all hover:bg-pink-900 border border-pink-800"
              >
                I am 18 or older
              </button>
            </div>
          </div>
        ) : (
          <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-pink-950/10 to-gray-950"></div>
            </div>

            <div className="relative z-10 text-center max-w-5xl mx-auto px-4">
              <div className="mb-8">
                <div className="w-24 h-24 mx-auto relative">
                  <BsShieldLock className="w-12 h-12 text-pink-400 mx-auto" />
                </div>
              </div>

              <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-pink-300 to-pink-100">
                Adult Content Writing Assistant
              </h1>

              <div className="h-8 mb-6">
                <p className="text-lg sm:text-xl text-pink-400 font-mono">
                  {prompts[currentPrompt]}
                </p>
              </div>

              <p className="text-lg mb-8 text-gray-300 max-w-3xl mx-auto">
                Create passionate and intimate stories with our discrete AI
                writing assistant. Professional-grade writing tools for adult
                content creators.
              </p>

              <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                <button
                  onClick={handleStartWriting}
                  className="group bg-pink-950 text-pink-400 font-mono py-4 px-8 rounded-lg text-lg transition-all hover:bg-pink-900 border border-pink-800 flex items-center gap-2"
                >
                  Start Writing
                  <BsStars className="w-5 h-5 group-hover:rotate-180 transition-transform" />
                </button>
                <button
                  onClick={handleViewStories}
                  className="text-cyan-400 border border-cyan-800 font-mono py-4 px-8 rounded-lg text-lg transition-all hover:bg-cyan-950"
                >
                  Read Stories
                </button>
              </div>

              <div className="mt-12 flex items-center justify-center gap-4 text-gray-400">
                <BsEyeSlash className="w-5 h-5" />
                <span className="text-sm">Private & Secure</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NSFWLandingPage;
