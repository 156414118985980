import React from "react";
import { Link } from "react-router-dom";
import { FiTrash2, FiEdit } from "react-icons/fi";

const SceneList = ({ scenes = [], type = "private", onDelete }) => {
  const getSceneUrl = (scene) => {
    return scene.isNsfw ? `/ai-scene-generator/nsfw` : `/ai-scene-generator`;
  };

  if (!scenes.length) {
    return null;
  }

  return (
    <div className="space-y-4">
      {scenes.map((scene, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-200"
        >
          <div className="flex justify-between items-start">
            <div className="flex-grow">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                {scene.title || "Untitled Scene"}
              </h3>
              <p className="text-gray-600 text-sm mb-2">
                {scene.premise?.substring(0, 150)}...
              </p>
              <div className="flex items-center text-sm text-gray-500">
                <span>
                  {new Date(
                    scene.created_at || Date.now()
                  ).toLocaleDateString()}
                </span>
                {scene.genre && (
                  <span className="ml-4 px-2 py-1 bg-gray-100 rounded-full">
                    {scene.genre}
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center space-x-2 ml-4">
              <Link
                to={{
                  pathname: getSceneUrl(scene),
                  state: {
                    scene_id: scene.id,
                    scene_title: scene.title,
                  },
                }}
                className="p-2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
                title="Edit Scene"
              >
                <FiEdit size={20} />
              </Link>
              {type === "private" && (
                <button
                  onClick={() => onDelete(scene.id)}
                  className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
                  title="Delete Scene"
                >
                  <FiTrash2 size={20} />
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SceneList;
