import React from "react";
import { FaEdit, FaSync, FaPlus, FaTrash } from "react-icons/fa";
import { useIsMobile } from "../utils/useIsMobile";

const ParagraphMenu = ({
  mode,
  onModeChange,
  onDelete,
  onInsert,
  darkMode,
}) => {
  const isMobile = useIsMobile();
  const buttonClass = (currentMode) =>
    `p-2 rounded-md transition-colors duration-200 flex items-center ${
      mode === currentMode
        ? "bg-yellow-500 text-white hover:bg-yellow-600"
        : "bg-yellow-100 text-yellow-700 hover:bg-yellow-200"
    }`;

  return (
    <>
      {!isMobile && (
        <div className="flex justify-start items-center space-x-2">
          <button
            className={buttonClass("rewrite")}
            onClick={() => onModeChange("rewrite")}
            title="Rewrite"
          >
            <FaSync className="w-4 h-4" />
            {mode === "rewrite" && <span className="ml-1">Rewrite</span>}
          </button>
          <button
            className={buttonClass("insert")}
            onClick={() => onModeChange("insert")}
            title="Insert"
          >
            <FaPlus className="w-4 h-4" />
            {mode === "insert" && <span className="ml-1">Insert</span>}
          </button>
          <button
            className={`p-2 rounded-md bg-red-500 ${
              darkMode ? "dark:bg-red-600" : ""
            } text-white hover:bg-red-600 ${
              darkMode ? "dark:hover:bg-red-700" : ""
            } transition-colors duration-200 flex items-center`}
            onClick={onDelete}
            title="Delete"
          >
            <FaTrash className="w-4 h-4" />
          </button>
        </div>
      )}
    </>
  );
};

export default ParagraphMenu;
