export const handleOutsideClick = (
  event,
  paragraphRef,
  menuRef,
  setActiveParagraph
) => {
  console.log("event", event);
  if (paragraphRef.current && menuRef.current) {
    const isParagraphClick = paragraphRef.current.contains(event.target);
    const isMenuClick = menuRef.current.contains(event.target);
    const isEditButtonClick = event.target.closest(".edit-button") !== null;

    if (!isParagraphClick && !isMenuClick && !isEditButtonClick) {
      console.log("setActiveParagraph to null");
      setActiveParagraph(null);
    }
  }
};

export const saveCursorPosition = (element) => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }
  return 0;
};

export const restoreCursorPosition = (element, position) => {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(element, 0);
  range.collapse(true);
  let currentPos = 0;
  const nodeStack = [element];
  let node;
  let foundStart = false;
  while (!foundStart && (node = nodeStack.pop())) {
    if (node.nodeType === Node.TEXT_NODE) {
      const nodeLength = node.length;
      if (currentPos + nodeLength >= position) {
        range.setStart(node, position - currentPos);
        foundStart = true;
      } else {
        currentPos += nodeLength;
      }
    } else {
      for (let i = node.childNodes.length - 1; i >= 0; i--) {
        nodeStack.push(node.childNodes[i]);
      }
    }
  }
  sel.removeAllRanges();
  sel.addRange(range);
};

export const longestCommonSubsequence = (text1, text2) => {
  const m = text1.length;
  const n = text2.length;
  const dp = Array(m + 1)
    .fill()
    .map(() => Array(n + 1).fill(0));

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (text1[i - 1] === text2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  let i = m,
    j = n;
  const lcs = [];
  while (i > 0 && j > 0) {
    if (text1[i - 1] === text2[j - 1]) {
      lcs.unshift({ char: text1[i - 1], index: j - 1 });
      i--;
      j--;
    } else if (dp[i - 1][j] > dp[i][j - 1]) {
      i--;
    } else {
      j--;
    }
  }
  return lcs;
};

export const diffSentences = (originalSentences, newSentences) => {
  const sentencesWithActions = [];
  let originalIndex = 0;
  let newIndex = 0;

  while (newIndex < newSentences.length) {
    const originalSentence = originalSentences[originalIndex];
    const newSentence = newSentences[newIndex];

    if (originalSentence && originalSentence.trim() === newSentence.trim()) {
      // No change
      sentencesWithActions.push({
        action: "no_change",
        sentence: newSentence.replace(/[.!?]+$/, ""), // Remove trailing punctuation if needed
      });
      originalIndex++;
      newIndex++;
    } else if (originalSentence && isSimilar(originalSentence, newSentence)) {
      // Edited sentence
      sentencesWithActions.push({
        action: "edit",
        original_sentence: originalSentence.replace(/[.!?]+$/, ""),
        edited_sentence: newSentence.replace(/[.!?]+$/, ""),
      });
      originalIndex++;
      newIndex++;
    } else {
      // Added sentence
      sentencesWithActions.push({
        action: "add",
        sentence: newSentence.replace(/[.!?]+$/, ""),
      });
      newIndex++;
    }
  }

  return sentencesWithActions;
};

function isSimilar(original, modified, threshold = 0.6) {
  const originalWords = original
    .toLowerCase()
    .replace(/[.!?]+$/, "")
    .split(/\s+/);
  const modifiedWords = modified
    .toLowerCase()
    .replace(/[.!?]+$/, "")
    .split(/\s+/);

  const commonWords = originalWords.filter((word) =>
    modifiedWords.includes(word)
  );
  const similarity = commonWords.length / originalWords.length;

  return similarity >= threshold;
}

export const convertSentencesToParagraph = (sentences) => {
  return sentences
    .filter((sentence) => sentence.action !== "remove")
    .map(
      (sentence) => sentence.rewritten_sentence || sentence.original_sentence
    )
    .join(" ");
};
