import React from "react";
import { actors } from "./data/actors";

const ActorStrip = ({ selectedActor, onActorSelect }) => {
  return (
    <div className="overflow-x-auto py-3 px-4">
      <div className="flex gap-2">
        {actors.map((actor) => (
          <button
            key={actor.id}
            onClick={() => onActorSelect(actor)}
            className={`flex flex-col items-center min-w-[3.5rem] p-2 rounded-lg transition-all
              ${
                selectedActor?.id === actor.id
                  ? "bg-purple-500/20 border border-purple-500"
                  : "hover:bg-gray-800"
              }`}
          >
            <span className="text-2xl mb-1">{actor.avatar}</span>
            <span className="text-[10px] text-gray-400 font-medium">
              {actor.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ActorStrip;
