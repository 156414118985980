import axios from "axios";

const API_VER = "api/v1";
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

export const getApiCallsHistory = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${API_VER}/api-calls-history`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API calls history:", error);
    throw error;
  }
};

export const rerunApiCall = async (route, params) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/${API_VER}${route}`,
      params,
      {
        headers: {
          "X-Is-Rerun": "true",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error re-running API call:", error);
    throw error;
  }
};

// Add this new function
export const clearApiCallsHistory = async () => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/${API_VER}/api-calls-history/clear`
    );
    return response.data;
  } catch (error) {
    console.error("Error clearing API calls history:", error);
    throw error;
  }
};
