export const generateSpeech = async (text) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/text-to-speech`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text }),
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to generate speech");
    }

    return response;
  } catch (error) {
    console.error("Error generating speech:", error);
    throw error;
  }
};

export const testVoice = async (prompt, voice = "alloy") => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/voice/test`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt,
        voice,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to generate voice");
  }

  return response;
};

export const recordTake = async (
  dialogue_id,
  dialogue,
  scene = "Simple conversation",
  mood = "neutral",
  voice = "alloy"
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/voice/take`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dialogue_id,
        dialogue,
        mood,
        scene,
        voice,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to generate voice");
  }

  return response;
};

export const getDialogues = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/voice/dialogues`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch dialogues");
  }
  return response.json();
};

export const createDialogue = async (dialogueData) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/voice/dialogues`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dialogueData),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to create dialogue");
  }

  return response.json();
};

export const clearCache = async (dialogueId, voice = "alloy") => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/voice/cache/${dialogueId}?voice=${voice}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to clear cache");
  }

  return response.json();
};

export const deleteDialogue = async (dialogueId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/voice/dialogues/${dialogueId}`,
    {
      method: "DELETE",
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to delete dialogue");
  }

  return await response.json();
};
