import React, { useState, useCallback } from "react";
import WordByWordRenderer from "../components/WordByWordRenderer";

const WordByWordDemo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [content, setContent] = useState([]);

  const responses = [
    [
      "As dusk settled over Verdantia, the air thickened with tension, a palpable energy that crackled like static before a storm.",
    ],
    [
      "As dusk settled over Verdantia, the air thickened with tension, a palpable energy that crackled like static before a storm. Lyra stood at the edge of her garden, the soft glow of her bioluminescent plants casting ethereal shadows on her determined face.",
    ],
    [
      "As dusk settled over Verdantia, the air thickened with tension, a palpable energy that crackled like static before a storm. Lyra stood at the edge of her garden, the soft glow of her bioluminescent plants casting ethereal shadows on her determined face.",
      "Another paragraph started",
    ],
  ];

  const onProgress = useCallback((newContent) => {
    setContent((prevContent) => {
      // If newContent has more paragraphs, use it entirely
      if (newContent.length > prevContent.length) {
        return newContent;
      }
      // Otherwise, update only the last paragraph
      const updatedContent = [...prevContent];
      updatedContent[updatedContent.length - 1] =
        newContent[newContent.length - 1];
      return updatedContent;
    });
  }, []);

  const generateContent = useCallback(() => {
    setIsGenerating(true);
    setCurrentIndex(0);
    setContent([]);

    const simulate = (index) => {
      onProgress(responses[index]);

      if (index < responses.length - 1) {
        setCurrentIndex(index + 1);
        setTimeout(() => simulate(index + 1), 2000);
      } else {
        setIsGenerating(false);
      }
    };

    setTimeout(() => simulate(0), 2000);
  }, [onProgress, responses]);

  return (
    <div>
      <h1>Simple Demo</h1>
      <button onClick={generateContent} disabled={isGenerating}>
        {isGenerating ? "Generating..." : "Generate Content"}
      </button>
      <div className="mt-4">
        <WordByWordRenderer paragraphs={content} typingSpeed={250} />
      </div>
    </div>
  );
};

export default WordByWordDemo;
