import React, { useState, useEffect } from "react";
import {
  FaPencilAlt,
  FaUndo,
  FaChevronDown,
  FaChevronUp,
  FaSyncAlt,
} from "react-icons/fa";
import ClearableTextarea from "./ClearableTextarea";
import { getSuggestions } from "../api/paragraphs";
import { getContextForMode } from "../utils/contextUtils";
import { useStory } from "../context/StoryContext";

const CustomInstructionSelector = ({
  onSuggestionSelect,
  instruction,
  onInstructionChange,
  placeholder,
  isSubmitted,
  onResetInstruction,
  preserveInstruction = false,
}) => {
  const { state } = useStory();
  const [preferences, setPreferences] = useState(() => {
    const savedPrefs = localStorage.getItem("storyGeneratorPreferences");
    return savedPrefs
      ? JSON.parse(savedPrefs)
      : {
          showSuggestionsPanel: true,
        };
  });
  const [expandedSuggestion, setExpandedSuggestion] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInstructionExpanded, setIsInstructionExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const updatePreferences = (updates) => {
    setPreferences((prev) => {
      const newPrefs = { ...prev, ...updates };
      localStorage.setItem(
        "storyGeneratorPreferences",
        JSON.stringify(newPrefs)
      );
      return newPrefs;
    });
  };

  useEffect(() => {
    if (!isSubmitted) {
      updatePreferences({ showSuggestionsPanel: true });
    }
  }, [isSubmitted]);

  useEffect(() => {
    console.log("showSuggestions:", preferences.showSuggestionsPanel);
  }, [preferences.showSuggestionsPanel]);

  const fetchSuggestions = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let context = getContextForMode("new_paragraphs_suggestions", state);
      const fetchedSuggestions = await getSuggestions(context);
      setSuggestions(fetchedSuggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      // Fallback to default suggestions if API call fails
      setSuggestions([
        "Add a plot twist. Introduce an unexpected event that changes the course of the story",
        "Introduce a new character. Describe their appearance, personality, and role in the story",
        "Describe the setting in more detail. Focus on the atmosphere, time period, and sensory details",
        "Add dialogue between characters. Reveal character personalities and advance the plot through conversation",
        "Create a suspenseful moment. Build tension and anticipation through pacing and description",
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    onSuggestionSelect(suggestion);
    onInstructionChange(suggestion);
    updatePreferences({ showSuggestionsPanel: false });
  };

  const toggleSuggestionExpand = (index) => {
    setExpandedSuggestion(expandedSuggestion === index ? null : index);
  };

  const handleTextareaFocus = () => {
    setIsFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="space-y-2">
      {isSubmitted ? (
        <div className="bg-blue-100 text-blue-800 p-3 rounded-md flex items-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              onResetInstruction();
              updatePreferences({ showSuggestionsPanel: true });
            }}
            className="mt-2 text-sm text-blue-600 hover:text-blue-800"
          >
            <FaUndo className="mr-2" />
          </button>
          <p
            className={`text-sm  ${
              isInstructionExpanded ? "" : "line-clamp-1"
            } cursor-pointer`}
            onClick={() => setIsInstructionExpanded(!isInstructionExpanded)}
          >
            {instruction}
          </p>
        </div>
      ) : preferences.showSuggestionsPanel ? (
        <div className="grid grid-cols-1 gap-2">
          <button
            onClick={fetchSuggestions}
            className="flex items-center justify-center px-3 py-2 text-sm bg-yellow-100 text-yellow-800 rounded-md hover:bg-yellow-200 transition-colors duration-200"
            disabled={isLoading}
          >
            <FaSyncAlt className={`mr-2 ${isLoading ? "animate-spin" : ""}`} />
            {isLoading ? "Loading suggestions..." : "Load suggestions"}
          </button>
          {suggestions &&
            suggestions.map((suggestion, index) => {
              const isExpanded = expandedSuggestion === index;

              return (
                <div key={index} className="relative">
                  <button
                    onClick={() => handleSuggestionSelect(suggestion)}
                    className="w-full text-left px-3 py-2 text-sm rounded-md transition-colors duration-200 bg-gray-100 text-gray-800 hover:bg-gray-200 pr-8"
                  >
                    <div
                      className={`overflow-hidden ${
                        isExpanded ? "line-clamp-none" : "line-clamp-1"
                      }`}
                    >
                      {suggestion}
                    </div>
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleSuggestionExpand(index);
                    }}
                    className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 ${
                      suggestion.split(" ").length > 10 ? "block" : "hidden"
                    }`}
                  >
                    {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                </div>
              );
            })}
          <button
            onClick={() => {
              updatePreferences({ showSuggestionsPanel: false });
              if (!preserveInstruction) {
                onInstructionChange("");
              }
            }}
            className="flex items-center justify-center px-3 py-2 text-sm bg-blue-100 text-blue-800 rounded-md hover:bg-blue-200 transition-colors duration-200"
          >
            <FaPencilAlt className="mr-2" /> Write custom instruction
          </button>
        </div>
      ) : (
        <div className="space-y-2">
          <ClearableTextarea
            value={instruction}
            onChange={onInstructionChange}
            onFocus={handleTextareaFocus}
            onBlur={handleTextareaBlur}
            placeholder={placeholder}
            rows={isFocused ? 5 : 3}
            className="w-full p-2 text-sm border border-blue-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:border-transparent"
          />
          {!isFocused && (
            <div className="flex justify-between">
              <button
                onClick={() =>
                  updatePreferences({ showSuggestionsPanel: true })
                }
                className="px-3 py-1 text-sm bg-yellow-100 text-yellow-800 rounded-md hover:bg-yellow-200 transition-colors duration-200 flex items-center"
              >
                <FaUndo className="mr-2" /> Back to suggestions
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomInstructionSelector;
