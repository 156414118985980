import React, { useState } from "react";
import {
  FaMinus,
  FaPlus,
  FaChevronDown,
  FaChevronUp,
  FaTimes,
  FaSync,
} from "react-icons/fa";
import { useStory } from "../context/StoryContext";
import { getSectionSummary } from "../api/paragraphs";
import { useIsMobile } from "../utils/useIsMobile";

const SectionBreak = ({
  index,
  onRemove,
  isAfterParagraph = false,
  summary = "",
  showSectionBreakButtons = false,
}) => {
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const { state, dispatch } = useStory();
  const isMobile = useIsMobile();

  const isCollapsed = state.collapsedSections
    ? state.collapsedSections.includes(index)
    : false;

  const onToggleCollapse = () => {
    dispatch({ type: "TOGGLE_SECTION_COLLAPSE", payload: index });
  };

  const fetchSectionSummary = async () => {
    setIsLoadingSummary(true);
    try {
      const previousParagraphs = [];
      let firstSectionBreakFound = false;
      let currIndex = index;
      if (
        state.paragraphs[currIndex].type &&
        state.paragraphs[currIndex].type === "section_break"
      ) {
        currIndex = currIndex - 1;
      }
      for (let i = currIndex; i >= 0; i--) {
        const currentItem = state.paragraphs[i];
        if (currentItem.type === "section_break") {
          firstSectionBreakFound = true;
          previousParagraphs.unshift(currentItem);
        } else {
          if (!firstSectionBreakFound) {
            previousParagraphs.unshift(currentItem);
          }
        }
      }
      const paragraphs = previousParagraphs.filter(
        (item) => item.type !== "section_break"
      );
      const prev_sections = previousParagraphs
        .filter((item) => item.type === "section_break")
        .map((item) => item.summary);
      // console.log(paragraphs, prev_sections);
      const generatedSummary = await getSectionSummary(
        paragraphs,
        prev_sections
      );
      return generatedSummary;
    } catch (error) {
      console.error("Error fetching section summary:", error);
    } finally {
      setIsLoadingSummary(false);
    }
  };

  const handleAddSectionBreak = async () => {
    const summary = await fetchSectionSummary();
    dispatch({
      type: "ADD_SECTION_BREAK",
      payload: { index: index + 1, summary: summary },
    });
  };

  const regenerateSummary = async (e) => {
    e.stopPropagation();
    const newSummary = await fetchSectionSummary();
    console.log("New summary ", newSummary);
    dispatch({
      type: "UPDATE_SECTION_BREAK_SUMMARY",
      payload: { index, summary: newSummary },
    });
  };

  if (isAfterParagraph) {
    return (
      <div className="relative py-2">
        {isLoadingSummary && (
          <div className="sm:flex items-center justify-center group">
            <div className="px-4 py-3">
              <p className="text-sm text-gray-600 italic">Loading summary...</p>
            </div>

            <div className="flex items-center justify-center">
              <div className="w-16 border-t-2 border-gray-300"></div>
              <div className="mx-4 text-gray-500">•</div>
              <div className="w-16 border-t-2 border-gray-300"></div>
            </div>
          </div>
        )}
        <div className="hidden sm:block">
          <div
            className="relative h-2 group cursor-pointer hover:h-8"
            onClick={handleAddSectionBreak}
          >
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <div className="flex items-center">
                <div className="w-16 border-t-2 border-gray-300"></div>
                <div className="mx-4 text-gray-500">•</div>
                <div className="w-16 border-t-2 border-gray-300"></div>
              </div>
            </div>

            <button
              className="py-2 px-4 text-gray-700 rounded-md flex items-center justify-center bg-gray-200 hover:bg-gray-300 opacity-0 group-hover:opacity-100"
              aria-label="Add section break"
            >
              <FaPlus className="text-gray-600 mr-2" /> Add Section Break
            </button>
          </div>
        </div>
        <div className="sm:hidden">
          {!isLoadingSummary && (
            <button
              onClick={handleAddSectionBreak}
              className="w-full py-2 px-4 bg-gray-200 text-gray-700 rounded-md flex items-center justify-center hover:bg-gray-300 transition-colors duration-200"
            >
              <FaPlus className="mr-2" /> Add Section Break
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative bg-white cursor-pointer group mt-4"
      onClick={onToggleCollapse}
    >
      {(!isMobile || showSectionBreakButtons) && (
        <>
          <button
            onClick={regenerateSummary}
            className="absolute right-0 top-0 transform -translate-y-1/2 w-4 h-4 rounded-full hover:bg-gray-200 transition-colors duration-200"
            aria-label="Regenerate summary"
          >
            <FaSync
              className={`text-gray-600 ${
                isLoadingSummary ? "animate-spin" : ""
              }`}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className="absolute left-0 top-0 transform -translate-y-1/2 w-4 h-4 bg-red-500 text-white rounded-full flex items-center justify-center sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-200 hover:bg-red-600 z-10"
            aria-label="Remove section break"
          >
            <FaTimes size={14} />
          </button>
        </>
      )}

      <div className="flex items-center justify-center mt-2">
        <div className="w-16 border-t-2 border-gray-300"></div>
        <div className="mx-4 text-gray-500">•</div>
        <div className="w-16 border-t-2 border-gray-300"></div>
      </div>
      {summary && (
        <>
          <div className="px-4 py-1 flex justify-between items-center relative">
            <p
              className={`text-sm text-gray-600 italic text-center flex-grow ${
                isMobile && isCollapsed ? "line-clamp-2" : ""
              }`}
            >
              {summary}
            </p>

            <button
              className="ml-2 p-1 rounded-full group-hover:bg-gray-200 transition-colors duration-200"
              aria-label={isCollapsed ? "Expand section" : "Collapse section"}
            >
              {isCollapsed ? (
                <FaChevronDown className="text-gray-600" />
              ) : (
                <FaChevronUp className="text-gray-600" />
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SectionBreak;
