import React, { useState, useEffect } from "react";
import PromptEditor from "../components/PromptEditor";
import { fetchPrompts } from "../api/prompts";
import {
  fetchModels,
  getCurrentModel,
  updateCurrentModel,
} from "../api/models";

function AdminPage() {
  const [prompts, setPrompts] = useState({});
  const [activeTab, setActiveTab] = useState("system");
  const [models, setModels] = useState([]);
  const [currentModel, setCurrentModel] = useState("");
  const [isUpdatingModel, setIsUpdatingModel] = useState(false);

  const loadPrompts = async () => {
    const fetchedPrompts = await fetchPrompts();
    setPrompts(fetchedPrompts);
  };

  const loadModels = async () => {
    const fetchedModels = await fetchModels();
    setModels(fetchedModels);
    const current = await getCurrentModel();
    setCurrentModel(current);
  };

  useEffect(() => {
    loadPrompts();
    loadModels();
  }, []);

  const handlePromptSave = () => {
    loadPrompts();
  };

  const handleModelChange = async (e) => {
    const newModel = e.target.value;
    setIsUpdatingModel(true);
    try {
      await updateCurrentModel(newModel);
      setCurrentModel(newModel);
    } catch (error) {
      console.error("Failed to update model:", error);
      // Optionally, show an error message to the user
    } finally {
      setIsUpdatingModel(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">
        Admin Panel - Prompt Management
      </h1>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="flex border-b">
          <button
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === "system"
                ? "bg-blue-500 text-white"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("system")}
          >
            System Prompts
          </button>
          <button
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === "user" ? "bg-blue-500 text-white" : "text-gray-600"
            }`}
            onClick={() => setActiveTab("user")}
          >
            User Prompts
          </button>
          <button
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === "model" ? "bg-blue-500 text-white" : "text-gray-600"
            }`}
            onClick={() => setActiveTab("model")}
          >
            Model Selection
          </button>
        </div>
        <div className="p-4">
          {activeTab === "system" &&
            Object.entries(prompts.systemPrompts || {}).map(([key, value]) => (
              <PromptEditor
                key={key}
                name={key}
                content={value}
                type="system"
                onSave={handlePromptSave}
              />
            ))}
          {activeTab === "user" &&
            Object.entries(prompts.userPrompts || {}).map(([key, value]) => (
              <PromptEditor
                key={key}
                name={key}
                content={value}
                type="user"
                onSave={handlePromptSave}
              />
            ))}
          {activeTab === "model" && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">Select Model</h2>
              <p className="mb-2">
                Current active model: <strong>{currentModel}</strong>
              </p>
              <select
                value={currentModel}
                onChange={handleModelChange}
                className="w-full p-2 border rounded"
                disabled={isUpdatingModel}
              >
                {models.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
              {isUpdatingModel && (
                <p className="mt-2 text-blue-500">Updating model...</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
